import { CareLabelPage, Layer, LayerWithFont } from '@customTypes/admin';
import { FontItem, FontListData } from '@customTypes/admin/careLabel/font';

/**
 * 폰트를 DOM에 삽입하는 함수
 *
 * @param fonts 사용된 폰트 목록
 * @returns {HTMLStyleElement} 폰트를 삽입한 style 요소
 */
export const embedFontsInDOM = (fonts: FontItem[]): HTMLStyleElement => {
  const styleTag = document.createElement('style');

  const fontFaces = fonts
    .map(
      (font) => `
      @font-face {
        font-family: '${font.font_family}';
        font-weight: ${font.font_weight.join(' ')};
        src: url('${font.font_url}');
      }
    `
    )
    .join('\n');

  styleTag.innerHTML = fontFaces;
  document.head.appendChild(styleTag);

  return styleTag;
};

/**
 * FontFace API를 사용하여 폰트를 로드하는 함수
 *
 * @param fontName 폰트 패밀리 이름
 * @param fontUrl 폰트 파일 URL
 * @returns {Promise<boolean>} 폰트 로드 성공 여부
 */
export const loadFont = async (fontName: string, fontUrl: string) => {
  if (!fontUrl) {
    console.error(`Font URL is missing for "${fontName}"`);
    return false;
  }

  try {
    const fontFace = new FontFace(fontName, `url(${fontUrl})`);
    await fontFace.load();
    document.fonts.add(fontFace);

    return true;
  } catch (error) {
    console.error(`Failed to load font "${fontName}"`, error);

    return false;
  }
};

/**
 * 레이어가 폰트 패밀리를 가지고 있는지 확인하는 타입 가드
 */
const hasFontFamily = (layer: Layer): layer is LayerWithFont =>
  Boolean(layer.style?.fontFamily);

/**
 * 페이지들에서 사용된 고유한 폰트 레이어들을 추출하는 함수
 */
const extractUniqueFontLayers = (pages: CareLabelPage[]): LayerWithFont[] => {
  const uniqueFontLayers = new Set(
    pages.flatMap((page) => page.layers.filter(hasFontFamily))
  );
  return Array.from(uniqueFontLayers);
};

/**
 * 사용된 폰트 목록 반환하는 함수
 */
export const getUsedFontList = (
  fontList: FontItem[],
  pages: CareLabelPage[]
) => {
  const uniqueFontLayers = extractUniqueFontLayers(pages);

  return fontList.filter((font) =>
    uniqueFontLayers.some(
      (layer) => layer.style.fontFamily === font.font_family
    )
  );
};
