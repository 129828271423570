import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { loadFont } from '@lib/utils';
import { useFontList } from '@lib/apis/admin';
import { fontListAtom } from '@store/admin/careLabel';
import {
  CareLabelPage,
  DropdownOption,
  FontItem,
  Layer,
  LayerWithFont,
} from '@customTypes/admin';

/**
 * 레이어가 폰트 패밀리를 가지고 있는지 확인하는 타입 가드
 */
const hasFontFamily = (layer: Layer): layer is LayerWithFont =>
  Boolean(layer.style?.fontFamily);

export const useFontController = () => {
  const { data: fontList } = useFontList();
  const [fonts, setFonts] = useAtom(fontListAtom);
  const [isFontReady, setIsFontReady] = useState(false);

  /**
   * 특정 폰트의 굵기 옵션들을 반환하는 함수
   */
  const getFontWeightOptions = useCallback(
    (currFont: string): DropdownOption[] => {
      if (!fonts) return [];

      return (
        fonts
          .find((font) => font.font_family === currFont)
          ?.font_weight.map((weight) => ({
            name: String(weight),
            label: String(weight),
          })) ?? []
      );
    },
    [fonts]
  );

  /**
   * 폰트 패밀리에 해당하는 폰트 URL을 반환하는 함수
   */
  const getFontUrl = useCallback(
    (fontFamily: string): string => {
      if (!fonts?.length) return '';

      return (
        fonts?.find((font) => font.font_family === fontFamily)?.font_url ?? ''
      );
    },
    [fonts]
  );

  /**
   * 폰트 로딩 프로미스들을 처리하고 상태를 관리하는 함수
   */
  const loadFontsWithErrorHandling = async (
    loadingPromises: Promise<boolean | undefined>[]
  ) => {
    setIsFontReady(false);
    try {
      await Promise.all(loadingPromises);
      setIsFontReady(true);
    } catch (error) {
      console.error('폰트 로딩 중 오류 발생:', error);
      setIsFontReady(false);
    }
  };

  /**
   * 페이지들에서 사용된 고유한 폰트 레이어들을 추출하는 함수
   */
  const extractUniqueFontLayers = (pages: CareLabelPage[]): LayerWithFont[] => {
    const uniqueFontLayers = new Set(
      pages.flatMap((page) => page.layers.filter(hasFontFamily))
    );
    return Array.from(uniqueFontLayers);
  };

  /**
   * 페이지에서 사용된 폰트들만 로드하는 함수
   * @param pages 케어라벨 페이지 배열
   */
  const loadUsedFonts = useCallback(
    async (pages: CareLabelPage[]) => {
      if (!fontList) return;
      setIsFontReady(false);

      const loadFontForLayer = async (layer: LayerWithFont) => {
        const fontUrl = getFontUrl(layer.style.fontFamily);
        if (!fontUrl) {
          console.warn(`폰트를 찾을 수 없습니다: ${layer.style.fontFamily}`);
          return;
        }
        return loadFont(layer.style.fontFamily, fontUrl);
      };

      const uniqueFontLayers = extractUniqueFontLayers(pages);
      await loadFontsWithErrorHandling(uniqueFontLayers.map(loadFontForLayer));
    },
    [fontList, getFontUrl]
  );

  /**
   * 모든 폰트를 로드하는 함수
   * @param fonts 폰트 아이템 배열
   */
  const loadAllFonts = useCallback(async (fonts: FontItem[]) => {
    if (!fonts) {
      setIsFontReady(false);
      return;
    }

    await loadFontsWithErrorHandling(
      fonts.map((font) => loadFont(font.font_family, font.font_url))
    );
  }, []);

  /**
   * 사용된 폰트 목록 반환하는 함수
   */
  const getUsedFontList = useCallback(
    async (pages: CareLabelPage[]) => {
      if (!fontList) return;

      const uniqueFontLayers = extractUniqueFontLayers(pages);
      const allFontList = [...fontList.default, ...fontList.uploaded];

      return allFontList.filter((font) =>
        uniqueFontLayers.some(
          (layer) => layer.style.fontFamily === font.font_family
        )
      );
    },
    [fontList]
  );

  /**
   * fontList가 업데이트될 때마다 전역 fonts 상태 업데이트
   */
  useEffect(() => {
    if (!fontList) return;

    const fontItmes: FontItem[] = [...fontList.default, ...fontList.uploaded];
    setFonts(fontItmes);
  }, [fontList]);

  return {
    isFontReady,
    fonts,
    loadUsedFonts,
    loadAllFonts,
    getUsedFontList,
    getFontWeightOptions,
  };
};
