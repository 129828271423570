import { useCallback, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  createLayerActions,
  selectedLayersAtom,
  getLayersAtom,
  getLayerLayoutsAtom,
  originalLayersForCloneAtom,
  currentPageIdAtom,
} from '@store/admin/careLabel';
import { useKeyboardEvents } from './useKeyboardEvents';

export const useLayerController = (pageId: string) => {
  // atoms와 actions는 useMemo로 메모이제이션
  const layersAtom = useMemo(() => getLayersAtom(pageId), [pageId]);
  const layoutsAtom = useMemo(() => getLayerLayoutsAtom(pageId), [pageId]);
  const actions = useMemo(() => createLayerActions(pageId), [pageId]);

  // atoms
  const [layers] = useAtom(layersAtom);
  const [layouts] = useAtom(layoutsAtom);
  const [selectedLayersState] = useAtom(selectedLayersAtom);
  const [originalLayersForClone] = useAtom(originalLayersForCloneAtom);

  // 현재 페이지의 선택된 레이어 계산 - useMemo로 메모이제이션
  const selectedLayers = useMemo(
    () =>
      selectedLayersState.pageId === pageId ? selectedLayersState.layerIds : [],
    [selectedLayersState, pageId]
  );

  const memoizedStates = useMemo(
    () => ({
      layers,
      selectedLayers,
      layouts,
      originalLayersForClone,
    }),
    [layers, selectedLayers, layouts, originalLayersForClone]
  );

  // actions
  const [, addLayer] = useAtom(actions.addLayer);
  const [, toggleSelection] = useAtom(actions.toggleLayerSelection);
  const [, clearSelection] = useAtom(actions.clearSelectedLayers);
  const [, copyLayers] = useAtom(actions.copySelectedLayers);
  const [, pasteLayers] = useAtom(actions.pasteSelectedLayers);
  const [, cloneLayers] = useAtom(actions.cloneSelectedLayers);
  const [, setTempOriginalLayers] = useAtom(actions.setTempOriginalLayers);
  const [, removeTempOriginalLayers] = useAtom(
    actions.removeTempOriginalLayers
  );
  const [, getLayerById] = useAtom(actions.getLayerById);
  const [, removeSelected] = useAtom(actions.removeSelectedLayers);
  const [, updateLayouts] = useAtom(actions.updateLayerLayouts);
  const [, updateLayerContent] = useAtom(actions.updateLayerContent);
  const [, updateLayerImageSrc] = useAtom(actions.updateLayerImageSrc);
  const [, updateQrData] = useAtom(actions.updateQrData);
  const [, updateLayerStyle] = useAtom(actions.updateLayerStyle);
  const [, addLayerStyle] = useAtom(actions.addLayerStyle);
  const [, alignLayers] = useAtom(actions.alignLayers);
  const [, initLayers] = useAtom(actions.initLayers);
  const [, redo] = useAtom(actions.redo);
  const [, undo] = useAtom(actions.undo);

  // 성능 최적화: 값들을 메모이제이션
  const memoizedActions = useMemo(
    () => ({
      addLayer,
      toggleSelection,
      clearSelection,
      copyLayers,
      pasteLayers,
      cloneLayers,
      setTempOriginalLayers,
      removeTempOriginalLayers,
      getLayerById,
      removeSelected,
      updateLayouts,
      updateLayerContent,
      updateQrData,
      updateLayerImageSrc,
      updateLayerStyle,
      addLayerStyle,
      alignLayers,
      initLayers,
      redo,
      undo,
    }),
    [
      addLayer,
      toggleSelection,
      clearSelection,
      copyLayers,
      pasteLayers,
      cloneLayers,
      setTempOriginalLayers,
      removeTempOriginalLayers,
      getLayerById,
      removeSelected,
      updateLayouts,
      updateLayerContent,
      updateQrData,
      updateLayerImageSrc,
      updateLayerStyle,
      addLayerStyle,
      alignLayers,
      initLayers,
      redo,
      undo,
    ]
  );

  // 단축키 설정
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      // e.stopPropagation();

      // 이벤트가 이미 처리되었는지 확인
      if (e.defaultPrevented) return;

      console.log('handleKeyDown called at useLayerController');

      // 선택된 레이어(들) 삭제
      if (e.key === 'Delete') {
        removeSelected();
      }

      // 선택된 레이어 모두 선택 해제
      if (e.key === 'Escape') {
        clearSelection();
      }

      // 선택된 레이어 복제
      if ((e.metaKey || e.ctrlKey) && e.key === 'c') {
        copyLayers();
      }

      // 선택된 레이어 붙여넣기
      if ((e.metaKey || e.ctrlKey) && e.key === 'v') {
        pasteLayers();
      }

      // 선택된 레이어 복제
      if ((e.metaKey || e.ctrlKey) && e.key === 'd') {
        e.stopPropagation();
        cloneLayers();
      }

      // 실행 취소/다시 실행
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        e.stopPropagation();

        if (e.shiftKey) {
          // Ctrl/Cmd + Shift + Z: Redo
          redo();
        } else {
          // Ctrl/Cmd + Z: Undo
          undo();
        }
      }
    },
    [
      removeSelected,
      clearSelection,
      copyLayers,
      pasteLayers,
      cloneLayers,
      redo,
      undo,
    ]
  );

  // // 이벤트 리스너 등록
  useKeyboardEvents({
    onDelete: removeSelected,
    onEscape: clearSelection,
    onCtrlC: copyLayers,
    onCtrlV: pasteLayers,
    onCtrlD: cloneLayers,
    onCtrlZ: undo,
    onCtrlShiftZ: redo,
  });

  return {
    ...memoizedStates,
    ...memoizedActions,
  };
};
