import { atom } from 'jotai';

export const checkedStateAtom = atom<boolean[]>([]);

export const isAllCheckedAtom = atom((get) => {
  const checkedState = get(checkedStateAtom);
  return checkedState.length > 0 && checkedState.every((checked) => checked);
});

export const numberOfCheckedAtom = atom((get) => {
  const checkedState = get(checkedStateAtom);
  return checkedState.filter((checked) => checked).length;
});
