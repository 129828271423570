import { useCallback, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import {
  VIEWPORT_INIT_SCALE,
  VIEWPORT_MAX_SCALE,
  VIEWPORT_MIN_SCALE,
  VIEWPORT_SCALE_STEP,
} from '@lib/constants/admin/careLabel';
import { viewportScaleAtom } from '@store/admin/careLabel';
import { useKeyboardEvents } from './useKeyboardEvents';

type Coordinate = { x: number; y: number };

interface ViewportEventHandlers {
  handleMouseDown: (e: React.MouseEvent) => void;
  handleMouseMove: (e: React.MouseEvent) => void;
  handleMouseUp: () => void;
}

interface ViewportState {
  scale: number;
  position: Coordinate;
  isPanning: boolean;
}

export const useViewportController = () => {
  const [scale, setScale] = useAtom(viewportScaleAtom);
  const [isPanning, setIsPanning] = useState<boolean>(false);
  const [position, setPosition] = useState<Coordinate>({ x: 0, y: 0 });
  const lastPosition = useRef<Coordinate>({ x: 0, y: 0 });
  const lastMousePosition = useRef<Coordinate>({ x: 0, y: 0 });

  // 뷰포트 상태 리셋
  const resetViewport = useCallback(() => {
    setScale(VIEWPORT_INIT_SCALE);
    setPosition({ x: 0, y: 0 });
    setIsPanning(false);
    document.body.style.cursor = 'default';
  }, [setScale]);

  // 뷰포트 스케일 변경
  const changeViewportScale = useCallback(
    (scale: string) => {
      setScale(Number(scale));
    },
    [setScale]
  );

  // 마우스 이벤트 핸들러
  const mouseEventHandlers = useCallback(
    (): ViewportEventHandlers => ({
      handleMouseDown: (e: React.MouseEvent) => {
        if (
          // Space Bar + Left Click
          (isPanning && e.buttons === 1) ||
          // Wheel Click
          e.buttons === 4
        ) {
          document.body.style.cursor = 'grabbing';
          lastPosition.current = position;
          lastMousePosition.current = { x: e.clientX, y: e.clientY };
        }
      },
      handleMouseMove: (e: React.MouseEvent) => {
        if (
          // Space Bar + Left Click
          (isPanning && e.buttons === 1) ||
          // Wheel Click
          e.buttons === 4
        ) {
          const dx = e.clientX - lastMousePosition.current.x;
          const dy = e.clientY - lastMousePosition.current.y;

          setPosition({
            x: lastPosition.current.x + dx,
            y: lastPosition.current.y + dy,
          });
        }
      },
      handleMouseUp: () => {
        document.body.style.cursor = 'default';
      },
    }),
    [isPanning, position]
  );

  // 휠 이벤트로 확대/축소 (Ctrl 키와 함께 사용)
  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (!e.ctrlKey && !e.metaKey) return;

      e.preventDefault();

      setScale((prev) => {
        const newScale =
          e.deltaY < 0
            ? Math.min(prev + VIEWPORT_SCALE_STEP, VIEWPORT_MAX_SCALE)
            : Math.max(prev - VIEWPORT_SCALE_STEP, VIEWPORT_MIN_SCALE);
        return Number(newScale.toFixed(1));
      });
    },
    [setScale]
  );

  const handlePanning = useCallback(() => {
    setIsPanning(true);
    document.body.style.cursor = 'grab';
  }, [setIsPanning]);

  const handlePanningUp = useCallback(() => {
    setIsPanning(false);
    document.body.style.cursor = 'default';
  }, [setIsPanning]);

  // 키보드 이벤트 리스너 등록
  useKeyboardEvents({
    onSpace: handlePanning,
    onSpaceUp: handlePanningUp,
    onCtrlZero: resetViewport,
  });

  // 휠 이벤트 리스너 등록
  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel);
  }, [handleWheel]);

  const state: ViewportState = {
    scale,
    position,
    isPanning,
  };

  return {
    ...state,
    ...mouseEventHandlers(),
    resetViewport,
    changeViewportScale,
  };
};
