import { atom } from 'jotai';
import { CareLabelDesignData } from '@customTypes/admin';

const initDesignData: CareLabelDesignData = {
  settings: {
    name: '',
    width: 0,
    height: 0,
    seamMargin: 0,
    sideMargin: 0,
    printFormat: '',
    colorMode: 'blackandwhite',
    backgroundColor: 'white',
  },
  pages: [],
};

export const careLabelDesignJsonAtom =
  atom<CareLabelDesignData>(initDesignData);
careLabelDesignJsonAtom.onMount = (setAtom) => {
  return () => {
    setAtom(initDesignData);
  };
};

// export const initializeDesignStateAtom = atom(null, (get, set) => {
//   set(careLabelDesignJsonAtom, initDesignData);
// });

// 썸네일 캡쳐할 페이지 ref atom
export const careLabelThumbRefAtom =
  atom<React.RefObject<HTMLDivElement> | null>(null);
careLabelThumbRefAtom.onMount = (setAtom) => {
  return () => {
    setAtom(null);
  };
};

// 목업 이미지용 refs 배열 atom 추가
export const mockupImageRefsAtom = atom<React.RefObject<HTMLDivElement>[]>([]);
mockupImageRefsAtom.onMount = (setAtom) => {
  return () => {
    setAtom([]);
  };
};
