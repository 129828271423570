import { atom } from 'jotai';
import { VIEWPORT_INIT_SCALE } from '@lib/constants/admin/careLabel';

// 케어라벨 페이지 (앞면/뒷면)
export const currentPageIdAtom = atom<string>('');

// 뷰포트 스케일 상태 (100% = 1)
export const viewportScaleAtom = atom<number>(VIEWPORT_INIT_SCALE);
viewportScaleAtom.onMount = (setAtom) => {
  return () => {
    setAtom(VIEWPORT_INIT_SCALE);
  };
};
