export const BREAK_POINTS = {
  DESKTOP: 1440, // 1440px 이하 -> 데스크탑
  LAPTOP: 1440, // 1440px 이하 (1280px ~ 1440px)
  TABLET: 1279, // 1279px 이하 (810px ~ 1279px)
  MOBILE: 810, // 810px 이하
} as const;

const mediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px)
`;

export const media = {
  desktop: mediaQuery(BREAK_POINTS.DESKTOP),
  laptop: mediaQuery(BREAK_POINTS.LAPTOP),
  tablet: mediaQuery(BREAK_POINTS.TABLET),
  mobile: mediaQuery(BREAK_POINTS.MOBILE),
};
