import { CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';

interface ButtonProps extends CSSProperties {
  label?: string | JSX.Element;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: React.ReactNode;
}

export default function Button({
  label,
  onClick,
  type = 'button',
  disabled = false,
  children,
  ...styles
}: ButtonProps) {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{ ...styles }}
    >
      {children ?? label}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: 0 16px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.black};
  word-break: keep-all;
  white-space: nowrap;

  /* Disabled */
  &:disabled {
    color: ${(props) => props.theme.colors.gray400};
    background-color: ${(props) => props.theme.colors.gray200};
    cursor: default;
  }

  /* Hovered */
  &:not(:disabled):hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.gray800};
  }

  /* Clicked */
  &:not(:disabled):active {
    color: ${(props) => props.theme.colors.gray400};
    background-color: ${(props) => props.theme.colors.black};
  }
`;
