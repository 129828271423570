import { GridItemLayout, Layer } from '@customTypes/admin/careLabel';

type SelectionSizeUpdateParams = {
  layer: Layer;
  targetLayout: GridItemLayout;
  newSize: number;
  maxMainSize: number;
  maxCrossSize: number;
  scale: number;
  isRatioLocked: boolean;
  isWidth: boolean;
};

type LayoutKeys = {
  main: 'w' | 'h';
  cross: 'h' | 'w';
  mainPos: 'x' | 'y';
  crossPos: 'y' | 'x';
};

const getLayoutKeys = (isWidth: boolean): LayoutKeys => ({
  main: isWidth ? 'w' : 'h',
  cross: isWidth ? 'h' : 'w',
  mainPos: isWidth ? 'x' : 'y',
  crossPos: isWidth ? 'y' : 'x',
});

const calculateCrossSize = (
  mainSize: number,
  aspectRatio: number,
  isWidth: boolean
) =>
  Math.max(
    1,
    Math.round(isWidth ? mainSize / aspectRatio : mainSize * aspectRatio)
  );

const calculateNextPos = (
  currentPos: number,
  targetPos: number,
  scale: number
) => targetPos + (currentPos - targetPos) * scale;

// 단일 선택 레이어 크기 조정
export const updateSingleSelection = ({
  layer,
  targetLayout,
  newSize,
  maxMainSize,
  maxCrossSize,
  isRatioLocked,
  isWidth,
}: SelectionSizeUpdateParams) => {
  const { main, cross } = getLayoutKeys(isWidth);
  const aspectRatio = targetLayout.w / targetLayout.h;

  let mainSize = newSize;
  let crossSize = isWidth
    ? Math.round(mainSize / aspectRatio)
    : Math.round(mainSize * aspectRatio);

  if (isRatioLocked) {
    if (mainSize > maxMainSize) {
      mainSize = maxMainSize;
      crossSize = calculateCrossSize(mainSize, aspectRatio, isWidth);
    }
    if (crossSize > maxCrossSize) {
      crossSize = maxCrossSize;
      mainSize = calculateCrossSize(crossSize, aspectRatio, !isWidth);
    }
  } else {
    mainSize = Math.min(mainSize, maxMainSize);
  }

  return {
    ...layer.layout,
    [main]: mainSize,
    ...(isRatioLocked && { [cross]: crossSize }),
  };
};

// 다중 선택 레이어 크기 조정 (바운딩 박스 기준)
export const updateMultipleSelection = ({
  layer,
  targetLayout,
  scale,
  maxMainSize,
  maxCrossSize,
  isRatioLocked,
  isWidth,
}: SelectionSizeUpdateParams) => {
  const { main, cross, mainPos, crossPos } = getLayoutKeys(isWidth);

  // 최대 크기 초과 여부 확인
  const isMaxSizeExceeded =
    (isWidth ? targetLayout.w >= maxMainSize : targetLayout.h >= maxMainSize) ||
    (isWidth ? targetLayout.h >= maxCrossSize : targetLayout.w >= maxCrossSize);

  // 이미 가로, 세로 중 하나 이상이 최대 크기일 때 더 키우려는 경우
  if (isMaxSizeExceeded && scale > 1) {
    return layer.layout;
  }

  //   w/h
  const currentSize = layer.layout[main];
  const nextMainSize = Math.max(1, Math.round(currentSize * scale));

  //   x/y
  const nextMainPos = calculateNextPos(
    layer.layout[mainPos],
    targetLayout[mainPos],
    scale
  );

  if (!isRatioLocked) {
    return {
      ...layer.layout,
      [main]: nextMainSize,
      [mainPos]: nextMainPos,
    };
  }

  // 비율 고정일 때는 반대 방향의 [크기]와 [위치]도 같은 비율로 조정
  const aspectRatio = layer.layout.w / layer.layout.h;
  //   h/w
  const nextCrossSize = calculateCrossSize(nextMainSize, aspectRatio, isWidth);
  //   y/x
  const nextCrossPos = calculateNextPos(
    layer.layout[crossPos],
    targetLayout[crossPos],
    scale
  );

  return {
    ...layer.layout,
    [main]: nextMainSize,
    [cross]: nextCrossSize,
    [mainPos]: nextMainPos,
    [crossPos]: nextCrossPos,
  };
};
