import { IconName } from '@components/@shared';

type OptionCardBase = {
  name: string;
  value: any;
  label: string;
  description: string;
};

export type OptionCard = OptionCardBase & {
  iconName: IconName;
};

export const CARE_LABEL_COLOR_MODES: OptionCard[] = [
  {
    name: 'blackandwhite',
    value: 'blackandwhite',
    label: '흑백 인쇄',
    description:
      '대부분의 케어라벨이 흑백으로 인쇄됩니다. 정보의 시인성이 뛰어나며, 세탁에 강하기 때문입니다.',
    iconName: 'CircleBlack',
  },
  {
    name: 'gray',
    value: 'gray',
    label: '회색 인쇄',
    description: '케어라벨의 색상을 회색으로 인쇄합니다.',
    iconName: 'CircleGray',
  },
  {
    name: 'gold',
    value: 'gold',
    label: '금박 인쇄',
    description: '케어라벨의 색상을 금박으로 인쇄합니다.',
    iconName: 'CircleGold',
  },
  {
    name: 'silver',
    value: 'silver',
    label: '은박 인쇄',
    description: '케어라벨의 색상을 은박으로 인쇄합니다.',
    iconName: 'CircleSilver',
  },
];

export const CARE_LABEL_CATEGORIES: OptionCard[] = [
  {
    name: 'clothing',
    value: '의류',
    label: '의류',
    description:
      '의류는 셔츠, 바지, 원피스 등 착용 가능한 모든 패션 아이템을 포함합니다.',
    iconName: 'Clothing',
  },
  {
    name: 'bag',
    value: '가방',
    label: '가방',
    description: '가방은 오토백, 크로스백, 토트백 등 모든 가방을 포함합니다.',
    iconName: 'Bag',
  },
  {
    name: 'shoes',
    value: '신발',
    label: '신발',
    description:
      '신발은 운동화, 구두, 샌들 등 발을 보호하고 스타일을 더하는 제품입니다.',
    iconName: 'Shoes',
  },
  {
    name: 'other',
    value: '기타',
    label: '기타',
    description: '기타는 의류, 가방, 신발에 포함되지 않는 제품입니다.',
    iconName: 'Other',
  },
];

export const CARE_LABEL_NATIONS: OptionCard[] = [
  {
    name: 'kr',
    value: 'kr',
    label: '대한민국',
    description: '국내에 유통하는 브랜드에게 추천하는 옵션입니다.',
    iconName: 'CircleKorea',
  },
  {
    name: 'eu',
    value: 'eu',
    label: '유럽',
    description: '유럽 수출을 염두하고 있는 브랜드에게 추천하는 옵션입니다.',
    iconName: 'CircleEurope',
  },
  {
    name: 'cn',
    value: 'cn',
    label: '중국',
    description: '중국 수출을 염두하고 있는 브랜드에게 추천하는 옵션입니다.',
    iconName: 'CircleChina',
  },
  {
    name: 'global',
    value: 'global',
    label: '전세계',
    description:
      '수출국가를 규정하지 않고, 모든 나라를 대응할 경우 선택합니다.',
    iconName: 'CircleGlobal',
  },
];

export const CARE_LABEL_PRINT_FORMATS = [
  {
    name: 'single',
    value: 'single',
    label: '단면 인쇄',
    description: '단면 인쇄',
  },
  {
    name: 'double',
    value: 'double',
    label: '양면 인쇄',
    description: '양면 인쇄',
  },
  {
    name: 'fold',
    value: 'fold',
    label: '절반 접기',
    description: '절반 접기',
  },
];
