// input, output의 단위는 모두 mm이지만,
// 디자인 모드에서 작업 중일 때는 px로 변환하여 사용
export const LABEL_SIZE_UNIT = 'px';

export const VIEWPORT_INIT_SCALE = 0.33 as const;

// 10%씩 확대/축소
export const VIEWPORT_SCALE_STEP = 0.1;
// 최소 50%
export const VIEWPORT_MIN_SCALE = 0.1;
// 최대 200%
export const VIEWPORT_MAX_SCALE = 2;
