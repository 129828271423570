import axios, { AxiosInstance } from 'axios';
import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
import { decrypt } from '@lib/utils/decrypt';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;
export const AES_KEY = process.env.REACT_APP_AES_KEY;

export const guestAxios: AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/user`,
});

guestAxios.interceptors.request.use((config) => {
  const nextConfig = config;
  const accessToken = localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');

  if (!accessToken || accessToken === 'undefined') {
    nextConfig.headers.Authorization = null;
  } else {
    nextConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return nextConfig;
});

guestAxios.interceptors.response.use(
  // 복호화
  (res) => {
    const data = res?.data?.result?.data;
    if (!data?.ed) {
      return res?.data?.result || res?.data || res;
    }

    const decryptedData = decrypt(data.ed, AES_KEY);
    const nextRes = { ...res, data: decryptedData };

    return nextRes;
  },
  // 로그인 관련 에러 처리
  async (err) => {
    const originalConfig = err.config;

    if (
      err.response &&
      err.response.status === 401 &&
      !originalConfig._retry &&
      // 회원정보 수정할 때 현재 비밀번호 잘못 입력한 경우 제외
      err.response.data.path !== '/user/updatemyinfo'
    ) {
      originalConfig._retry = true;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      localStorage.removeItem('role');
      localStorage.removeItem('chargeBrand');

      return (window.location.href = `${CLIENT_BASE_URL}${ADMIN_PAGE_PATH.guest.login}`);
    }

    return Promise.reject(err);
  }
);
