import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { mmToPx } from '@lib/utils';
import { careLabelDesignJsonAtom } from '@store/admin/careLabel';
import { CareLabelColorMode } from '@customTypes/admin';

interface LabelFormat {
  name: string;
  width: number;
  height: number;
  seamMargin: number;
  sideMargin: number;
  center: {
    x: number;
    y: number;
  };
  printFormat: string;
  actualWidth: number;
  actualHeight: number;
  colorMode: CareLabelColorMode;
  backgroundColor: string;
  unit: number;
  pxWidth: number; // px
  pxHeight: number; // px
  pxSeamMargin: number; // px
  pxSideMargin: number; // px
  pxCenter: {
    x: number; // px
    y: number; // px
  };
  pxActualWidth: number;
  pxActualHeight: number;
}

export function useLabelFormat(): LabelFormat {
  const designData = useAtomValue(careLabelDesignJsonAtom);

  return useMemo(() => {
    const {
      name,
      width,
      height,
      seamMargin,
      sideMargin,
      printFormat,
      colorMode,
      backgroundColor,
    } = designData.settings;

    const actualWidth = width - sideMargin * 2;
    const actualHeight = height - seamMargin * (printFormat === 'fold' ? 2 : 1);

    const center = {
      x: Math.round(actualWidth / 2),
      y: Math.round(actualHeight / 2),
    };

    return {
      unit: mmToPx(1),
      name,
      width,
      height,
      seamMargin,
      sideMargin,
      center,
      printFormat,
      actualWidth,
      actualHeight,
      colorMode,
      backgroundColor,
      pxWidth: mmToPx(width),
      pxHeight: mmToPx(height),
      pxSeamMargin: mmToPx(seamMargin),
      pxSideMargin: mmToPx(sideMargin),
      pxCenter: {
        x: mmToPx(center.x),
        y: mmToPx(center.y),
      },
      pxActualWidth: mmToPx(actualWidth),
      pxActualHeight: mmToPx(actualHeight),
    };
  }, [designData]);
}
