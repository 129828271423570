import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface FormSectionContainerProps extends PropsWithChildren {
  title?: string;
  required?: boolean;
}

export default function FormSectionContainer({
  title,
  required,
  children,
}: FormSectionContainerProps) {
  return (
    <Container>
      <SectionTitleContainer>
        <TitleContainer>
          {title ? <SectionTitle>{title}</SectionTitle> : null}
          <RequiredText>
            {typeof required === 'boolean' &&
              (required ? '(필수)' : '(선택사항)')}
          </RequiredText>
        </TitleContainer>
      </SectionTitleContainer>
      <FieldsContainer>{children}</FieldsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const RequiredText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
