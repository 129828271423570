import { useCallback, useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  checkedStateAtom,
  isAllCheckedAtom,
  numberOfCheckedAtom,
} from '@store/checkbox';

export interface UseCheckboxesReturn {
  checkedState: boolean[];
  isAllChecked: boolean;
  numberOfChecked: number;
  toggleCheckbox: (index: number) => void;
  toggleAllCheckboxes: () => void;
  initCheckedState: (length: number) => void;
  resetCheckedState: () => void;
}

export const useCheckboxes = (
  initialLength: number = 0
): UseCheckboxesReturn => {
  const [checkedState, setCheckedState] = useAtom(checkedStateAtom);
  const isAllChecked = useAtomValue(isAllCheckedAtom);
  const numberOfChecked = useAtomValue(numberOfCheckedAtom);

  const toggleCheckbox = useCallback((index: number) => {
    setCheckedState((prev) => {
      const newCheckedState = [...prev];
      newCheckedState[index] = !newCheckedState[index];
      return newCheckedState;
    });
  }, []);

  const toggleAllCheckboxes = useCallback(() => {
    setCheckedState((prev) => {
      const shouldCheck = !prev.every((checked) => checked);
      return new Array(prev.length).fill(shouldCheck);
    });
  }, []);

  const initCheckedState = useCallback((length: number) => {
    setCheckedState((prev) => {
      if (prev.length !== length) {
        return new Array(length).fill(false);
      }
      return prev;
    });
  }, []);

  const resetCheckedState = useCallback(() => {
    setCheckedState((prev) => {
      if (prev.some((checked) => checked)) {
        return new Array(prev.length).fill(false);
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    initCheckedState(initialLength);
  }, [initialLength]);

  useEffect(() => {
    return () => {
      resetCheckedState();
    };
  }, []);

  return {
    checkedState,
    isAllChecked,
    numberOfChecked,
    toggleCheckbox,
    toggleAllCheckboxes,
    initCheckedState,
    resetCheckedState,
  };
};
