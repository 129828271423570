import { atom } from 'jotai';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { toKoreanISO, convertToDateRangeLabel } from '@lib/utils';
import { DateValue } from '@components/admin/@shared/Calendar/Calendar';

/**
 * 페이지 번호
 */
export const pageNumberAtom = atom<number>(1);
// offset
export const pageOffsetAtom = atom<number>(1);
/**
 * 페이지당 아이템 개수
 */
export const numberOfItemsAtom = atom<string>('10');
numberOfItemsAtom.onMount = (setAtom) => {
  return () => {
    setAtom('10');
  };
};
/**
 * 의류 분류 필터
 */
export const categoryFilterAtom = atom<string>('');
categoryFilterAtom.onMount = (setAtom) => {
  return () => {
    setAtom('');
  };
};

export const startDateFilterAtom = atom<Value>(new Date());
startDateFilterAtom.onMount = (setAtom) => {
  return () => {
    setAtom(new Date());
  };
};
export const endDateFilterAtom = atom<Value>(new Date());
endDateFilterAtom.onMount = (setAtom) => {
  return () => {
    setAtom(new Date());
  };
};

export const dateRangeFilterAtom = atom<DateValue>([null, null]);
dateRangeFilterAtom.onMount = (setAtom) => {
  return () => {
    setAtom([null, null]);
  };
};

export const formattedDateRangeFilterAtom = atom<string[]>((get) => {
  const dates = get(dateRangeFilterAtom);

  if (Array.isArray(dates)) {
    return dates.map((date) => (date === null ? '' : toKoreanISO(date)));
  }

  return ['', ''];
});

export const dateRangeLabelAtom = atom<string>((get) =>
  convertToDateRangeLabel(get(formattedDateRangeFilterAtom))
);
