import { CustomAxiosResponseType } from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';
import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

/**
 * [ADMIN] DPP 추가 API
 */
const createDpp = async (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = (await brandAdminAxios.post(
    '/dpp',
    data,
    config
  )) as CustomAxiosResponseType;

  return res?.msg;
};

export const useDppCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['dppCreate'],
    mutationFn: (data: any) => createDpp(data),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppDetail'] }),
      ]);
    },
  });
};
