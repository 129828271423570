import { theme } from '@lib/styles/theme';
import { NewLayerInfo, LayerCategory, PageSide } from '@customTypes/admin';
import { IconName } from '@components/admin/@shared/atoms';

export const LAYER_CATEGORIES_ICON_NAME: Record<LayerCategory, IconName> = {
  text: 'LayerText',
  square: 'LayerSquare',
  line: 'LayerLine',
  image: 'LayerImage',
  icon: 'LayerIcon',
  variable: 'LayerSize',
  qr: 'LayerQr',
};

export const LAYER_CATEGORIES_LABEL: Record<LayerCategory, string> = {
  text: '텍스트',
  square: '사각형',
  line: '직선',
  image: '이미지',
  icon: '아이콘',
  variable: '변수',
  qr: 'QR',
  /**
   * @todo QR코드 추가
   */
};

export const PAGE_SIDE_LABEL: Record<PageSide, string> = {
  front: '앞면',
  back: '뒷면',
};

// Default layer configurations
export const DEFAULT_LAYER_CONFIGS: Record<LayerCategory, NewLayerInfo> = {
  square: {
    type: 'div',
    category: 'square',
    layout: { x: 0, y: 0, w: 6, h: 6 },
    style: { backgroundColor: theme.colors.black },
  },
  line: {
    type: 'div',
    category: 'line',
    layout: { x: 0, y: 0, w: 12, h: 1 },
    style: {
      position: 'relative',
      backgroundColor: 'none',
      border: 'none',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      height: '1px',
    },
  },
  text: {
    type: 'div',
    category: 'text',
    content: 'TEXT',
    layout: { x: 0, y: 0, w: 8, h: 6 },
    style: {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '1.2em',
      letterSpacing: '0em',
      textAlign: 'left',
      color: theme.colors.black,
      padding: '1px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
    },
  },
  variable: {
    type: 'div',
    category: 'variable',
    content: '',
    layout: { x: 0, y: 0, w: 5, h: 3 },
    style: {
      fontFamily: 'Pretendard Variable',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '1.2em',
      letterSpacing: '0em',
      textAlign: 'left',
      color: theme.colors.black,
      padding: '1px',
    },
  },
  image: {
    type: 'div',
    category: 'image',
    layout: { x: 0, y: 0, w: 10, h: 10 },
    style: {},
  },
  icon: {
    type: 'div',
    category: 'icon',
    layout: { x: 0, y: 0, w: 3, h: 3 },
    style: {},
  },
  qr: {
    type: 'div',
    category: 'qr',
    layout: { x: 0, y: 0, w: 3, h: 3 },
    style: {},
  },
};
