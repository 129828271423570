import axios, { AxiosInstance } from 'axios';
import { AES_KEY, API_BASE_URL, CLIENT_BASE_URL } from '@lib/apis/user/@core';
import { decrypt } from '@lib/utils/decrypt';
import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
export * from './guest';

export const brandAdminAxios: AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/admin-brand`,
});

/**
 * @todo brand admin일 경우 Endpoint 수정
 */
brandAdminAxios.interceptors.request.use((config) => {
  const nextConfig = config;
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken || accessToken === 'undefined') {
    nextConfig.headers.Authorization = null;
  } else {
    nextConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return nextConfig;
});

brandAdminAxios.interceptors.response.use(
  // 복호화
  (res) => {
    const data = res?.data?.result?.data;
    if (!data?.ed) {
      return res?.data?.result || res?.data || res;
    }

    const decryptedData = decrypt(data.ed, AES_KEY);
    const nextRes = { ...res, data: decryptedData };

    return nextRes;
  },
  // 로그인 관련 에러 처리
  async (err) => {
    const originalConfig = err.config;

    if (err.response && err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      localStorage.removeItem('role');
      localStorage.removeItem('chargeBrand');

      return (window.location.href = `${CLIENT_BASE_URL}${ADMIN_PAGE_PATH.guest.login}`);
    }

    return Promise.reject(err);
  }
);
