export type CareLabelInfoKey = (typeof CARE_LABEL_INFO_LIST)[number]['key'];

export const CARE_LABEL_INFO_LIST = [
  {
    label: '컬러',
    key: 'color_mode',
    unit: '',
  },
  {
    label: '국가',
    key: 'nation',
    unit: '',
  },
  {
    label: '가로',
    key: 'width',
    unit: 'mm',
  },
  {
    label: '세로',
    key: 'height',
    unit: 'mm',
  },
  {
    label: '시접',
    key: 'seam_margin',
    unit: 'mm',
  },
  {
    label: '좌우 여백',
    key: 'side_margin',
    unit: 'mm',
  },
  {
    label: '접지 방식',
    key: 'option',
    unit: '',
  },
  {
    label: '디지털 라벨',
    key: 'dpp',
    unit: '',
  },
  {
    label: '재질',
    key: 'textile_name',
    unit: '',
  },
] as const;

export const SIZE_INPUT_FIELDS = [
  { name: 'width', label: '가로' },
  { name: 'height', label: '세로' },
  { name: 'seam_margin', label: '시접' },
  { name: 'side_margin', label: '좌우 여백' },
] as const;

export const PRINT_FORMAT_OPTIONS = [
  {
    name: 'single',
    label: '단면 인쇄',
    description: '한쪽 면에만 인쇄합니다.',
  },
  {
    name: 'double',
    label: '양면 인쇄',
    description: '양쪽 면에 인쇄합니다.',
  },
  {
    name: 'fold',
    label: '절반 접기',
    description: '한쪽 면에 인쇄한 후, 절반으로 접습니다.',
  },
] as const;

export const LABEL_SIZE_MIN_HEIGHT = 32 as const;
export const LABEL_SIZE_MAX_HEIGHT = 240 as const;
