import { media } from '@lib/constants/breakpoints';
import styled from 'styled-components';

interface BadgeProps {
  label: string;
}

export default function Badge({ label }: BadgeProps) {
  return <Container>{label}</Container>;
}

const Container = styled.span`
  width: fit-content;
  display: inline-flex;
  background-color: #59e09c;
  color: #fff;
  border-radius: 100px;
  padding: 6px 12px 8px;
  font-size: 16px;
  font-weight: 400;

  ${media.tablet} {
    padding: 6px 8px;
    font-size: 14px;
  }

  ${media.mobile} {
    padding: 4px 6px;
    font-size: 10px;
  }
`;
