import {
  CARE_LABEL_COLOR_MODES,
  CARE_LABEL_NATIONS,
  CARE_LABEL_PRINT_FORMATS,
} from '@lib/constants/admin/careLabel/new';

interface LabelOption {
  name: string;
  label: string;
  value?: string;
}

const FORMAT_MAPPINGS = {
  color_mode: CARE_LABEL_COLOR_MODES,
  nation: CARE_LABEL_NATIONS,
  option: CARE_LABEL_PRINT_FORMATS,
} as const;

const getFormatedValue = (
  key: keyof typeof FORMAT_MAPPINGS,
  value: string
): string => {
  const mapping = FORMAT_MAPPINGS[key]?.find((item: LabelOption) =>
    item.name.includes(value)
  );
  return mapping ? mapping.label || mapping.value || value : value;
};

const getFormatedBooleanValue = (value: boolean): string =>
  value ? '사용함' : '사용안함';

const getFormatedValueWithUnit = (
  value: string | number,
  unit?: string
): string => (unit ? `${value} ${unit}` : String(value));

export const getCareLabelFormatedValue = (
  key: string,
  value: unknown,
  unit?: string
): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'boolean') {
    return getFormatedBooleanValue(value);
  }

  if (key in FORMAT_MAPPINGS) {
    return getFormatedValue(key as keyof typeof FORMAT_MAPPINGS, String(value));
  }

  return getFormatedValueWithUnit(value as string | number, unit);
};
