import { CSSProperties } from 'react';
import styled from 'styled-components';

interface CancelButtonProps extends CSSProperties {
  onClick: () => void;
  label?: string;
}

export default function CancelButton({ onClick, label }: CancelButtonProps) {
  return (
    <Button type="button" onClick={onClick}>
      {label || '취소'}
    </Button>
  );
}

const Button = styled.button`
  padding: 4px 10px;
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray400};
`;
