import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CustomAxiosResponseType,
  LibraryImageItem,
  LibraryIconsData,
  FontListData,
  ImageUsageInfo,
} from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';

/**
 * [ADMIN] 케어라벨 이미지 라이브러리 조회 API
 */
const getImageLibrary = async (): Promise<LibraryImageItem[]> => {
  const res = await brandAdminAxios.get('/care-label-project-img');

  return res?.data;
};

export const useImageLibrary = () => {
  return useQuery({
    queryKey: ['imageLibrary'],
    queryFn: () => getImageLibrary(),
  });
};

/**
 * [ADMIN] 케어라벨 이미지 라이브러리 업로드 API
 */
const uploadImageOnLibrary = async (
  formData: FormData
): Promise<LibraryImageItem> => {
  const res = await brandAdminAxios.post('/care-label-project-img', formData);

  return res?.data;
};

export const useImageUploadOnLibrary = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: FormData) => uploadImageOnLibrary(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['imageLibrary'] });
    },
  });
};

/**
 * [ADMIN] 케어라벨 이미지 사용 여부 확인 API (삭제 직전 호출)
 */
const checkImageUsage = async (imgUrl: string): Promise<ImageUsageInfo> => {
  const res = await brandAdminAxios.get(
    `/care-label-project-img-check?img_url=${imgUrl}`
  );

  return res?.data;
};

export const useImageUsageCheck = (imgUrl: string) => {
  return useQuery({
    queryKey: ['imageUsageCheck'],
    queryFn: () => checkImageUsage(imgUrl),
    enabled: false,
  });
};

/**
 * [ADMIN] 케어라벨 이미지 라이브러리 삭제 API
 */
const deleteImageOnLibrary = async (imgUrl: string) => {
  const res = (await brandAdminAxios.delete(
    `/care-label-project-img?img_url=${imgUrl}`
  )) as CustomAxiosResponseType;

  return res?.msg;
};

export const useImageDeleteOnLibrary = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (imgUrl: string) => deleteImageOnLibrary(imgUrl),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['imageLibrary'] });
    },
  });
};

/**
 * [ADMIN] 케어라벨 아이콘 조회 API
 */
const getIconLibrary = async (): Promise<LibraryIconsData> => {
  const res = await brandAdminAxios.get('/care-label-icon');

  return res?.data;
};

export const useIconLibrary = () => {
  return useQuery({
    queryKey: ['iconLibrary'],
    queryFn: () => getIconLibrary(),
  });
};

/**
 * [ADMIN] 케어라벨 폰트 리스트 조회 API
 */
const getFontList = async (searchKeyword?: string): Promise<FontListData> => {
  const query = searchKeyword ? `?name=${searchKeyword}` : '';
  const res = await brandAdminAxios.get(`/care-label-font${query}`);

  console.log(res?.data);

  return res?.data;
};

export const useFontList = (searchKeyword?: string) => {
  return useQuery({
    queryKey: ['fontList'],
    queryFn: () => getFontList(searchKeyword),
    // enabled: false,
    // gcTime: 0,
  });
};

/**
 * [ADMIN] 케어라벨 폰트 업로드 API
 */
const uploadFont = async (formData: FormData) => {
  const res = await brandAdminAxios.put('/care-label-font', formData);

  return res?.data;
};

export const useFontUpload = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: FormData) => uploadFont(formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fontList'] });
    },
  });
};
