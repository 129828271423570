import { AxiosRequestConfig } from 'axios';
import { useAtomValue } from 'jotai';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DppListData } from '@customTypes/admin';
import {
  categoryFilterAtom,
  itemCountAtom,
  pageNumberAtom,
  searchKeywordsAtom,
} from '@store/admin/dpp';
import { brandAdminAxios } from './@core/brand';

interface DppListParams {
  page: number;
  items: string;
}

/**
 * [ADMIN] DPP 상세 조회 API
 */
const getDppList = async (params: DppListParams): Promise<DppListData> => {
  const searchParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (!value) return acc;

    if (acc) acc += '&';
    acc += `${key}=${value}`;
    return acc;
  }, '');

  const res = await brandAdminAxios.get(`/dpp-list?${searchParams}`);

  return res?.data;
};

export const useDppList = () => {
  const page = useAtomValue(pageNumberAtom);
  const items = useAtomValue(itemCountAtom);
  const category = useAtomValue(categoryFilterAtom);
  const searchKeywords = useAtomValue(searchKeywordsAtom);

  const params = {
    page,
    items,
    category,
    ...searchKeywords,
  };

  return useQuery({
    queryKey: ['dppList', page, items, category, searchKeywords],
    queryFn: () => getDppList(params),
    placeholderData: (previousData) => previousData,
  });
};

/**
 * [ADMIN] DPP 발행 아이템 리스트 엑셀 다운로드 API
 */
const downloadIssuedDppItemList = async (no: number) => {
  const data = { no };

  const config: AxiosRequestConfig = {
    responseType: 'blob',
  };

  const res = await brandAdminAxios.post(`/download-dpp-excel`, data, config);

  return res;
};

export const useIssuedDppItemListExcelDownload = () => {
  return useMutation({
    mutationKey: ['dppInfoExcelDownload'],
    mutationFn: (no: number) => downloadIssuedDppItemList(no),
  });
};

/**
/**
 * [ADMIN] DPP 삭제 API
 */
const deleteDpp = async (no: number[]) => {
  const data = {
    no,
  };
  const res = await brandAdminAxios.delete('/dpp', { data });

  return res?.data;
};

export const useDppDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDpp,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dppList'] });
    },
  });
};
