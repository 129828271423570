import { CSSProperties, MouseEvent } from 'react';
import styled from 'styled-components';

interface ButtonSecondaryProps extends CSSProperties {
  label: string | JSX.Element;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element;
  disabled?: boolean;
  className?: string;
}

export default function ButtonSecondary({
  label,
  onClick,
  icon,
  type = 'button',
  disabled = false,
  className,
  ...styles
}: ButtonSecondaryProps) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.();
  };

  return (
    <StyledButton
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={className}
      style={{ ...styles }}
    >
      {icon}
      {label}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: 0 16px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  word-break: keep-all;
  white-space: nowrap;

  /* Disabled */
  &:disabled {
    color: ${(props) => props.theme.colors.gray200};
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.white};
    cursor: default;
  }

  /* Hovered */
  &:not(:disabled):hover {
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.gray100};
  }

  /* Clicked */
  &:not(:disabled):active {
    color: ${(props) => props.theme.colors.gray500};
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.white};
  }
`;
