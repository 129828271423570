import { OrderStatusType } from '@customTypes/admin/careLabel/orderPayment';
import { ProgressBarStepConfigType } from '@customTypes/admin/careLabel/progress';

export const ORDER_STATUS_CONFIG = [
  { status: 'PENDING', label: '입금 대기중', statusCode: 1 },
  { status: 'CONFIRMED', label: '주문 완료', statusCode: 2 },
  { status: 'REVIEWING', label: '검수중', statusCode: 3 },
  { status: 'PRODUCING', label: '제작중', statusCode: 4 },
  { status: 'SHIPPING', label: '배송중', statusCode: 5 },
  { status: 'DELIVERED', label: '배송 완료', statusCode: 6 },
  { status: 'CANCELED', label: '취소됨', statusCode: 7 },
] as const;

export const ORDER_LIST_HEADERS = [
  { id: 'orderNumber', label: '주문번호' },
  { id: 'productName', label: '품명' },
  { id: 'quantity', label: '수량' },
  { id: 'price', label: '주문가격' },
  { id: 'orderDate', label: '주문날짜' },
  { id: 'orderStatus', label: '주문상태' },
  { id: 'invoiceNumber', label: '송장 번호' },
  { id: 'details', label: '상세보기' },
] as const;

export const ORDER_PAYMENT_STATUS_CONFIG: Record<
  OrderStatusType,
  ProgressBarStepConfigType
> = {
  입금전: {
    status: 'PENDING',
    statusCode: 1,
    label: '입금 대기중',
    icon: 'PaymentBeforePay',
    buttonLabel: '부분 취소 불가',
  },
  주문완료: {
    status: 'CONFIRMED',
    statusCode: 2,
    label: '주문 완료',
    icon: 'PaymentConfirmed',
    buttonLabel: '주문 취소',
  },
  검수중: {
    status: 'REVIEWING',
    statusCode: 3,
    label: '검수중',
    icon: 'PaymentUnderReview',
    buttonLabel: '주문 취소',
  },
  제작중: {
    status: 'PRODUCING',
    statusCode: 4,
    label: '제작중',
    icon: 'PaymentMaking',
    buttonLabel: '취소 불가',
  },
  배송중: {
    status: 'SHIPPING',
    statusCode: 5,
    label: '배송중',
    icon: 'PaymentShipping',
    buttonLabel: '취소 불가',
  },
  배송완료: {
    status: 'DELIVERED',
    statusCode: 6,
    label: '배송완료',
    icon: 'PaymentDelivered',
    buttonLabel: '취소 불가',
  },
  취소완료: {
    status: 'CANCELED',
    statusCode: 7,
    label: '취소됨',
    icon: 'PaymentCanceled',
    buttonLabel: '취소됨',
  },
  입금기한만료: {
    status: 'CANCELED',
    statusCode: 7,
    label: '입금 기한 만료',
    icon: 'PaymentCanceled',
    buttonLabel: '취소됨',
  },
} as const;

export const TOGGLE_BUTTON_CONFIG = {
  expanded: {
    label: '접기',
    icon: 'ChevronUp',
  },
  collapsed: {
    label: '전체보기',
    icon: 'ChevronDown',
  },
};
