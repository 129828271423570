import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropdownOption } from '@customTypes/admin';
import { Icon } from '@components/@shared';
import { theme } from '@lib/styles/theme';

interface DropdownProps {
  options?: DropdownOption[];
  value?: string;
  onChange?: (value: string) => void;
}

export default function DropDown({ options, value, onChange }: DropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>(value || '');

  const handleDropdownOpen = () => {
    if (!options || options.length === 0) return;

    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (name: string, label: string) => {
    onChange?.(name);
    setLabel(label);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (!options) return;

    const selected = options.find((option) => option.name === value);
    if (!selected) return;

    setLabel(selected.label);
  }, [value, options]);

  return (
    <Container>
      <DropdownButton
        type="button"
        onClick={handleDropdownOpen}
        $isOpen={isDropdownOpen}
      >
        <Label $isSelected={!!value && value.length > 0}>{label}</Label>
        <ArrowDownIcon $isOpen={isDropdownOpen}>
          <Icon name="ChevronDown" fill="gray400" />
        </ArrowDownIcon>
      </DropdownButton>
      {isDropdownOpen && options && options.length > 0 && (
        <Options>
          {options.map(({ name, label }) => (
            <Option
              onClick={() => handleOptionClick(name, label)}
              key={name}
              $isSelected={name === value}
            >
              {label}
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
}

const DROPDOWN_HEIGHT = '44px';
const ARROW_ICON_SIZE = '24px';

const Container = styled.div`
  position: relative;
  width: 100%;
  align-self: center;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  cursor: pointer;
`;

interface StyledDropdownProps {
  $isOpen?: boolean;
}

const DropdownButton = styled.button<StyledDropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${DROPDOWN_HEIGHT};
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.gray400};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  /* transition: all 100ms ease-in; */

  ${({ theme, $isOpen }) =>
    $isOpen &&
    `
      border-bottom-left-radius: ${theme.borderRadiuses.none};
      border-bottom-right-radius: ${theme.borderRadiuses.none};
      border-bottom: 1px solid ${theme.colors.gray200};
    `}
`;

interface StyledLabelProps {
  $isSelected?: boolean;
}

const Label = styled.label<StyledLabelProps>`
  display: block;
  width: 100%;
  margin: 0;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.gray200 : theme.colors.gray400};
  cursor: pointer;
`;

const ArrowDownIcon = styled.div<StyledDropdownProps>`
  display: flex;
  align-items: center;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 300ms
    ${({ theme }) => theme.animations.customCubicBezier};

  & > img {
    width: ${ARROW_ICON_SIZE};
    height: ${ARROW_ICON_SIZE};
    object-fit: contain;
  }
`;

const Options = styled.ul<StyledDropdownProps>`
  position: absolute;
  top: ${DROPDOWN_HEIGHT};
  left: 0;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiuses.lg};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiuses.lg};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-top: none;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  padding: 0 4px 4px 4px;
  padding-top: 4px;
  max-height: 360px;
  overflow-y: auto;

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Firefox 등 다른 브라우저 지원을 위한 설정 */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

interface OptionProps {
  $isSelected?: boolean;
}

const Option = styled.li<OptionProps>`
  padding: 10px 6px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 100ms ease-in;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray50};
  }

  & + & {
    margin-top: 10px;
  }
`;
