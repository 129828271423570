import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { accessTokenAtomWithPersistence } from '@store/login';
import { ImageListData } from '@customTypes/admin/image';
import { initProductImageSearchKeywords } from '@pages/admin/ProductImageListPage';
import { adminAxios } from './@core';

export const NUMBER_OF_PRODUCT_IMAGE_LIST_ITEM = 10;

/**
 * [ADMIN] 제품 이미지 리스트 조회 API
 */
const fetchProductImageList = async (
  searchKeywords?: typeof initProductImageSearchKeywords,
  page?: number
): Promise<ImageListData> => {
  let queryParam = `?page=${page || 1}&items=${NUMBER_OF_PRODUCT_IMAGE_LIST_ITEM}`;

  if (searchKeywords?.brand) {
    queryParam += `&brand=${searchKeywords.brand}`;
  }
  if (searchKeywords?.name) {
    queryParam += `&name=${searchKeywords.name}`;
  }

  const res = await adminAxios.get('/product-img' + queryParam);

  return res?.data;
};

export const useProductImageList = (
  searchKeywords?: typeof initProductImageSearchKeywords,
  page?: number
) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  return useSuspenseQuery({
    queryKey: ['productImageList', searchKeywords, page, accessToken],
    queryFn: () => fetchProductImageList(searchKeywords, page),
  });
};

/**
 * [ADMIN] 제품 이미지 생성 API
 */
const addProductImage = async (formData: FormData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await adminAxios.post(`/product-img`, formData, config);

  return res?.data;
};

export const useProductImageAdd = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['productImageAdd'],
    mutationFn: (formData: FormData) => addProductImage(formData),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['productImageList'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] 제품 이미지 삭제 API
 */
const removeProductImage = async (imageList: string[]) => {
  const data = { names: imageList };
  const res = await adminAxios.delete(`/product-img`, { data });

  return res?.data;
};

export const useProductImageRemove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['productImageRemove'],
    mutationFn: (imageList: string[]) => removeProductImage(imageList),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['productImageList'] }),
      ]);
    },
  });
};
