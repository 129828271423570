type EventType = {
  preventDefault: () => void;
  stopPropagation: () => void;
};

/**
 * 이벤트 기본 동작 및 전파 방지
 */
export const preventEventPropagation = <T extends EventType>(e: T) => {
  e.preventDefault();
  e.stopPropagation();
};
