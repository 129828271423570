import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useCareLabelDetail } from '@lib/apis/admin';
import { ProductSizeSchemaType } from '@lib/apis/admin/@schema';
import {
  selectedOptionObjAtom,
  selectedToolAtom,
} from '@store/admin/careLabel';
import { ProductSizesOptionList } from '@customTypes/admin';
import { useLayerController } from './useLayerController';
import { useLabelFormat } from './useLabelFormat';

interface ProductSizeController {
  allFilteredOptionList: ProductSizeSchemaType[];
  selectedOptionObj: ProductSizeSchemaType;
  selectedOptionFieldList: ProductSizesOptionList;
  handleSizeOptionChange: (sizeOption: ProductSizeSchemaType) => void;
  handleSizeVariableAdd: (key: string, value: string) => void;
}

export const useProductSizeController = (
  pageId: string
): ProductSizeController => {
  const [, setSelectedTool] = useAtom(selectedToolAtom);
  const [selectedOptionObj, setSelectedOptionObj] = useAtom(
    selectedOptionObjAtom
  );
  const {
    layers,
    addLayer,
    updateLayerContent,
    clearSelection,
    toggleSelection,
  } = useLayerController(pageId);
  const { center } = useLabelFormat();
  const { data: careLabelDetail } = useCareLabelDetail();

  const allFilteredOptionList = useMemo(() => {
    if (!careLabelDetail?.size?.length) return [];

    return careLabelDetail.size.map((size) =>
      Object.fromEntries(
        Object.entries(size).filter(
          ([key, value]) => key !== 'no' && Boolean(value)
        )
      )
    ) as ProductSizeSchemaType[];
  }, [careLabelDetail?.size]);

  const selectedOptionFieldList = useMemo(
    () => Object.entries(selectedOptionObj) as ProductSizesOptionList,
    [selectedOptionObj]
  );

  /**
   * 사이즈 옵션 객체를 필터링하고 변환하는 함수
   */
  const filterValidFields = useCallback((sizeOption: ProductSizeSchemaType) => {
    const filteredEntries = Object.entries(sizeOption).filter(
      ([key, value]) => key !== 'no' && Boolean(value)
    );
    return Object.fromEntries(filteredEntries) as ProductSizeSchemaType;
  }, []);

  /**
   * 필터링된 사이즈 옵션을 상태로 설정하는 함수
   */
  const updateFilteredSizeOption = useCallback(
    (sizeOption: ProductSizeSchemaType) => {
      const processedOption = filterValidFields(sizeOption);
      setSelectedOptionObj(processedOption);
    },
    [filterValidFields, setSelectedOptionObj]
  );

  /**
   * 모든 variable 레이어의 content를 업데이트하는 함수
   */
  const updateAllSizeLayersContent = useCallback(
    (sizeOption: ProductSizeSchemaType) => {
      if (!layers) return;

      layers.forEach((layer) => {
        if (layer.category !== 'variable') return;

        const nextValue =
          sizeOption[layer.key as keyof ProductSizeSchemaType] || '';
        updateLayerContent(layer.id, nextValue);
      });
    },
    [layers, updateLayerContent]
  );

  const handleSizeOptionChange = useCallback(
    (sizeOption: ProductSizeSchemaType) => {
      updateFilteredSizeOption(sizeOption);
      updateAllSizeLayersContent(sizeOption);
      // 모든 작업 수행 후 선택된 레이어 선택 해제
      clearSelection();
    },
    [updateFilteredSizeOption, updateAllSizeLayersContent, clearSelection]
  );

  const handleSizeVariableAdd = useCallback(
    (key: string, value: string) => {
      const w = 4;
      const h = 2;

      const layerId = addLayer({
        category: 'variable',
        content: value,
        layout: {
          x: center.x - w / 2,
          y: center.y - h / 2,
          w,
          h,
        },
        key,
      });

      toggleSelection(layerId);
    },
    [addLayer, center, toggleSelection]
  );

  // 초기 사이즈 옵션 설정
  useEffect(() => {
    if (allFilteredOptionList.length > 0) {
      setSelectedOptionObj(allFilteredOptionList[0] as ProductSizeSchemaType);
    }
  }, [allFilteredOptionList]);

  return {
    allFilteredOptionList,
    selectedOptionObj,
    selectedOptionFieldList,
    handleSizeOptionChange,
    handleSizeVariableAdd,
  };
};
