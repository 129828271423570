import { useCallback, useEffect } from 'react';

interface KeyboardHandlers {
  onSpace?: (e: KeyboardEvent) => void;
  onSpaceUp?: (e: KeyboardEvent) => void;
  onCtrlZero?: (e: KeyboardEvent) => void;
  onDelete?: (e: KeyboardEvent) => void;
  onEscape?: (e: KeyboardEvent) => void;
  onCtrlC?: (e: KeyboardEvent) => void;
  onCtrlV?: (e: KeyboardEvent) => void;
  onCtrlD?: (e: KeyboardEvent) => void;
  onCtrlZ?: (e: KeyboardEvent) => void;
  onCtrlShiftZ?: (e: KeyboardEvent) => void;
}

export const useKeyboardEvents = (handlers: KeyboardHandlers) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      //   console.log(e);

      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (e.defaultPrevented) return;

      // Space 관련
      if (e.code === 'Space' && !e.repeat) {
        // e.preventDefault();
        handlers.onSpace?.(e);
        return;
      }

      // Ctrl/Cmd 조합키
      if (e.metaKey || e.ctrlKey) {
        switch (e.key) {
          case '0':
            e.preventDefault();
            handlers.onCtrlZero?.(e);
            break;
          case 'c':
            handlers.onCtrlC?.(e);
            break;
          case 'v':
            handlers.onCtrlV?.(e);
            break;
          case 'd':
            e.preventDefault();
            handlers.onCtrlD?.(e);
            break;
          case 'z':
            e.preventDefault();
            if (e.shiftKey) {
              handlers.onCtrlShiftZ?.(e);
            } else {
              handlers.onCtrlZ?.(e);
            }
            break;
        }
        return;
      }

      // 단일 키
      switch (e.key) {
        case 'Delete':
          handlers.onDelete?.(e);
          break;
        case 'Escape':
          handlers.onEscape?.(e);
          break;
      }
    },
    [handlers]
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        // e.preventDefault();
        handlers.onSpaceUp?.(e);
      }
    },
    [handlers]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);
};
