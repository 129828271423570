import styled from 'styled-components';
import { Icon, IconName } from '../Icon';
import { ProgressStatusType } from '@customTypes/admin/careLabel/progress';

interface IconWrapperBoxProps {
  $status?: ProgressStatusType;
  $width: number;
  $height: number;
  $borderWidth: number;
  $orderStatus?: string;
}

interface IconWrapperProps extends IconWrapperBoxProps {
  iconName: IconName;
  fill?: string;
  stroke?: string;
}

export default function IconWrapper({
  iconName,
  $status = 'default',
  $borderWidth = 2,
  $width,
  $height,
  fill,
  $orderStatus,
}: IconWrapperProps) {
  const finalFill =
    fill ??
    ($status === 'active'
      ? 'white'
      : $status === 'inactive'
        ? 'gray200'
        : 'black');

  return (
    <IconWrapperBox
      $borderWidth={$borderWidth}
      $width={$width}
      $height={$height}
      $status={$status}
      $orderStatus={$orderStatus}
    >
      <Icon name={iconName} fill={finalFill} />
    </IconWrapperBox>
  );
}

const IconWrapperBox = styled.div<IconWrapperBoxProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  border-radius: 50%;
  background-color: ${({ theme, $status }) =>
    $status === 'active' ? theme.colors.black : theme.colors.white};
  border: ${({ theme, $status, $borderWidth }) =>
    `${$borderWidth}px solid ${
      $status === 'inactive' ? theme.colors.gray200 : theme.colors.black
    }`};

  &::after {
    content: ${({ $orderStatus }) =>
      $orderStatus ? `"${$orderStatus}"` : '""'};
    width: fit-content;
    position: absolute;
    top: 100%; // 부모 요소의 높이만큼 아래로 이동
    left: 50%; // 부모 요소의 중간점으로 이동
    transform: translateX(
      -50%
    ); // 자신의 너비의 절반만큼 왼쪽으로 이동하여 중앙 정렬
    margin-top: 2px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;
    color: ${({ $status, theme }) =>
      $status === 'inactive' ? theme.colors.gray200 : 'inherit'};
  }
`;
