import { CSSProperties } from 'react';
import styled from 'styled-components';
import { PropsWithRequiredChildren } from '@customTypes/admin';

interface ContainerProps extends CSSProperties, PropsWithRequiredChildren {}

function Container({ children, ...styles }: ContainerProps) {
  return <StyledContainer style={styles}>{children}</StyledContainer>;
}

function Title({ children }: PropsWithRequiredChildren) {
  return <ContainerTitle>{children}</ContainerTitle>;
}

export default Object.assign(Container, {
  Title,
});

const StyledContainer = styled.div`
  margin: ${({ theme }) => theme.spaces.lg} 0;
  padding: ${({ theme }) => `${theme.spaces.xxl} ${theme.spaces.xxxl}`};
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContainerTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
`;
