import { atom } from 'jotai';
import { OrderStatusType, SearchKeywords } from '@customTypes/admin';

export interface OrderStatusState {
  status: OrderStatusType | null;
  statusCode: number | null;
}

export const initCareLabelSearchKeywords: SearchKeywords = {
  name: '',
};

export const careLabelSearchKeywordsAtom = atom<SearchKeywords>(
  initCareLabelSearchKeywords
);

export const currPageAtom = atom<number>(1);
export const pageOffsetAtom = atom<number>(1);
export const numberOfItemsAtom = atom<number>(8);
export const numberOfPaymentItemsAtom = atom<number>(10);

export const selectedCareLabelListAtom = atom<number[]>([]);
selectedCareLabelListAtom.onMount = (setAtom) => {
  return () => {
    setAtom([]);
  };
};

export const orderStatusAtom = atom<OrderStatusState>({
  status: null,
  statusCode: null,
});

export const isOrderCanceledAtom = atom<boolean>(false);
