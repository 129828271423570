import styled, { css } from 'styled-components';
import Badge from './Badge';
import { media } from '@lib/constants/breakpoints';
import { motion } from 'framer-motion';
import { scrollFadeInUpVariants } from '@lib/constants/motion';

interface RowWrapperProps {
  imagePosition: 'right' | 'left';
  badge: string;
  title1: string;
  title2: string;
  description: string;
  image: string;
}

export default function RowWrapper({
  imagePosition,
  badge,
  title1,
  title2,
  description,
  image,
}: RowWrapperProps) {
  return (
    <SectionContainer>
      <AnimationWrapper
        $imagePosition={imagePosition}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.3 }}
        variants={scrollFadeInUpVariants}
      >
        <Content>
          <Badge label={badge} />
          <TitleWrapper>
            <Title>{title1}</Title>
            <Title>{title2}</Title>
          </TitleWrapper>
          <Description>{description}</Description>
        </Content>
        <ImageWrapper>
          <img src={image} alt={title1} />
        </ImageWrapper>
      </AnimationWrapper>
    </SectionContainer>
  );
}

const SectionContainer = styled.section`
  padding: 180px 0;
  overflow: hidden;
  transform: translateZ(0);
  will-change: transform;

  ${media.tablet} {
    padding: 135px 0;
  }

  ${media.mobile} {
    padding: 90px 0;
  }
`;

const AnimationWrapper = styled(motion.div)<{
  $imagePosition: 'right' | 'left';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-direction: ${(props) =>
    props.$imagePosition === 'right' ? 'row' : 'row-reverse'};

  ${media.tablet} {
    gap: 30px;
  }

  ${media.mobile} {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: nowrap;

  ${media.mobile} {
    gap: 10px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  overflow-wrap: break-word;
  word-break: keep-all;
  width: 100%;

  ${media.tablet} {
    font-size: 36px;
  }

  ${media.mobile} {
    font-size: 24px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;

  ${media.tablet} {
    font-size: 18px;
  }

  ${media.mobile} {
    font-size: 12px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;

  img {
    border-radius: 20px;
    width: 648px;
    height: 518.4px;
    object-fit: contain;
  }

  ${media.tablet} {
    img {
      width: 100%;
      height: auto;
    }
  }
`;
