import styled from 'styled-components';
import { LANDING_BG_COLOR } from '@lib/constants/imageUrls';
import { media } from '@lib/constants/breakpoints';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-image: url(${LANDING_BG_COLOR});
  background-size: 208.5px;
  background-repeat: repeat;
  min-height: 100vh;
  display: grid;

  ${media.tablet} {
    padding: 0 10px;
  }
`;

const Container = styled.main`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;

  ${media.laptop} {
    // max-width: 1000px;
  }

  ${media.tablet} {
    // max-width: 800px;
  }

  ${media.mobile} {
    // max-width: 100%;
    // padding: 0 16px;
  }
`;
