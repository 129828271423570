import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { AES_KEY, API_BASE_URL } from '@lib/apis/user/@core';
import { AdminLoginData } from '@customTypes/admin';
import { decrypt } from '@lib/utils/decrypt';
import { LoginInfoSchemaType } from './@schema/signUp';
import { LoginSchemaType } from './@schema';

/**
 * [ADMIN] 브랜드 admin 로그인 API
 */
const loginBrandAdmin = async (
  dataObj: LoginSchemaType
): Promise<AdminLoginData> => {
  const config = {
    baseURL: API_BASE_URL,
  };

  const res = await axios.post('/user/signin', dataObj, config);

  const data = res?.data?.result?.data;
  if (!data?.ed) {
    return res?.data?.result?.data || res?.data?.result || res?.data || res;
  }

  console.log(data);

  const decryptedData = decrypt(data.ed, AES_KEY);
  return decryptedData;
};

export const useBrandAdminLogin = () => {
  return useMutation({
    mutationKey: ['brandAdminLogin'],
    mutationFn: (dataObj: LoginSchemaType) => loginBrandAdmin(dataObj),
  });
};
