import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useHover } from '@lib/hooks';

interface RadioButtonProps extends CSSProperties {
  onChange?: () => void;
  isChecked?: boolean;
  disabled?: boolean;
}

export default function RadioButton({
  onChange,
  isChecked,
  disabled,
  ...styles
}: RadioButtonProps) {
  const { isHovered, handleHovered, handleUnhovered } = useHover();

  return (
    <Container
      style={styles}
      onMouseEnter={handleHovered}
      onMouseLeave={handleUnhovered}
    >
      <Input
        type="radio"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <Circle $isChecked={isChecked} $isHovered={isHovered && !disabled} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 3px;
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
    `}
`;

interface CircleProps {
  $isChecked?: boolean;
  $isHovered?: boolean;
}

const Circle = styled.div<CircleProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid
    ${({ theme, $isChecked }) =>
      $isChecked ? theme.colors.black : theme.colors.gray200};

  ${({ theme, $isChecked, $isHovered }) =>
    $isHovered &&
    !$isChecked &&
    `
        border-color: ${theme.colors.gray400};
    `}
`;
