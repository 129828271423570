import {
  DEGREE_SYMBOL_REGEX,
  NUMERIC_WITH_NEGATIVE_REGEX,
} from '@lib/constants/admin';

/**
 * 숫자를 여섯자리수로 만들어주는 함수
 *
 * @param number 숫자
 * @param digit 자리수
 * @returns {string}
 */
export const makeNumberToSextupleDigit = (
  number: number,
  digit?: number
): string => {
  return String(number).padStart(digit || 6, '0');
};

/**
 * 소수점 길이 맞춰주는 함수
 */
export const setDecimalPlaces = (number: number, decimal: number): string => {
  return number.toFixed(decimal);
};

/**
 * 전화번호 하이픈(-) 넣어주는 함수
 */
export const insertHypensInPhoneNumber = (phoneNumber: string): string => {
  return (
    phoneNumber.slice(0, 3) +
    '-' +
    phoneNumber.slice(3, 7) +
    '-' +
    phoneNumber.slice(7)
  );
};

/**
 * 전화번호에 공백을 넣어주는 함수
 */
export const formatPhoneNumberWithSpaces = (phone: string) => {
  if (!phone) return '';

  return phone
    .replace(/[^0-9]/g, '') // 숫자가 아닌 문자 제거
    .replace(/^(\d{3})(\d{4})(\d{4})$/, '$1 $2 $3'); // 000 0000 0000 형식으로 변환
};

/**
 * 회전 각도 기호(°) 제거해주는 함수
 */
export const removeDegreeSymbol = (value: string): string => {
  console.log('value', value);
  console.log('replaced value', value.replace(DEGREE_SYMBOL_REGEX, ''));

  return value.replace(DEGREE_SYMBOL_REGEX, '');
};

/**
 * 회전 각도 기호(°) 삽입해주는 함수
 */
export const insertDegreeSymbol = (value: string): string => {
  return value + '°';
};

// 숫자 정규화 (03 -> 3)
export const normalizeNumber = (value: string): string => {
  return value === '' ? value : String(Number(value));
};

/**
 * 회전 각도 값 검증 함수
 */
export const validateRotateValue = (value: string): boolean => {
  return NUMERIC_WITH_NEGATIVE_REGEX.test(value);
};

/**
 * 배열에서 입력 값보다 크면서 가장 가까운 값 찾아주는 함수
 */
export const findClosestLargerNumber = (array: number[], target: number) => {
  // 배열에서 target보다 큰 값만 필터링
  const largerNumbers = array.filter((num) => num > target);

  // 큰 값이 없다면 null 반환
  if (largerNumbers.length === 0) {
    return null;
  }

  // 큰 값 중에서 가장 가까운 값 찾기
  return largerNumbers.reduce((closest, num) =>
    num - target < closest - target ? num : closest
  );
};
