import styled from 'styled-components';
import { LANDING_SECTION_HOME_IMAGE_URL_6 } from '@lib/constants/imageUrls';
import { Link } from 'react-router-dom';
import { media } from '@lib/constants/breakpoints';
import { scrollFadeInUpVariants } from '@lib/constants/motion';
import { motion } from 'framer-motion';

export default function Section04() {
  return (
    <Container>
      <AnimationWrapper
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.3 }}
        variants={scrollFadeInUpVariants}
      >
        <Content>
          <Title>
            간편한 케어 라벨 제작, <br />그 이상의 가치.
          </Title>
          <Wrap>
            <Description>
              무료체험으로 디자인부터 정품 인증,
              <br />
              리셀 데이터까지 직접 경험해보세요.
            </Description>
            <StyledButton>
              <Link to="/login">로그인</Link>
            </StyledButton>
          </Wrap>
        </Content>
        <ImageWrapper>
          <img src={LANDING_SECTION_HOME_IMAGE_URL_6} alt="라벨 이미지" />
        </ImageWrapper>
      </AnimationWrapper>
    </Container>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 140px;
  transform: translateZ(0);
  will-change: transform;

  ${media.tablet} {
    padding: 105px 40px 0;
  }

  ${media.mobile} {
    padding: 70px 10px 0;
  }
`;

const AnimationWrapper = styled(motion.div)`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  gap: 140px;

  ${media.tablet} {
    flex-direction: column;
    gap: 20px;
  }
`;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;

  ${media.tablet} {
    font-size: 36px;
  }

  ${media.mobile} {
    font-size: 24px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 68px;

  ${media.tablet} {
    gap: 15px;
  }

  ${media.mobile} {
    gap: 10px;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 24px;
  font-weight: 400;

  ${media.tablet} {
    font-size: 18px;
  }

  ${media.mobile} {
    font-size: 12px;
  }
`;

const ImageWrapper = styled.div`
  // flex: 1;
  display: flex;

  img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const StyledButton = styled.button`
  width: 200px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  padding: 12px 12px 12px 16px;
  border-radius: 10px;
  font-weight: 500;
  transition: all 0.3s ease;

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Hovered */
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.black};

    a {
      color: inherit;
    }
  }
`;
