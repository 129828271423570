import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface ToolTipProps {
  children: React.ReactNode;
  title: string;
  description: string;
}

export default function ToolTip({
  children,
  title,
  description,
}: ToolTipProps) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <>
      <TooltipContainer
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </TooltipContainer>
      {createPortal(
        <TooltipContent isVisible={isVisible}>
          <TooltipTitle>{title}</TooltipTitle>
          <TooltipDescription>{description}</TooltipDescription>
        </TooltipContent>,
        document.body
      )}
    </>
  );
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

interface TooltipContentProps {
  isVisible: boolean;
}

const TooltipContent = styled.div<TooltipContentProps>`
  display: flex;
  flex-direction: column;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  position: absolute;
  background: ${(props) => props.theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1.5;
  z-index: 10;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  width: 240px;
  height: auto;
  padding: 10px 12px;
  border-radius: 8px;

  /* 툴팁 위치 설정 */
  top: 280px;
  right: 250px;
`;

const TooltipTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
`;

const TooltipDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  margin-top: 4px;
`;
