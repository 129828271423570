import { useEffect, useMemo, useState } from 'react';
import { FieldValues, Path, UseFormWatch } from 'react-hook-form';
import { ZodSchema } from 'zod';

interface UseFormPartialValidationProps<TFormData extends FieldValues> {
  watch: UseFormWatch<TFormData>;
  schema: ZodSchema<any>;
}

/**
 * 스키마에 정의된 필드들의 유효성을 검사하는 훅
 */
export function useFormPartialValidation<TFormData extends FieldValues>({
  watch,
  schema,
}: UseFormPartialValidationProps<TFormData>) {
  const [isValid, setIsValid] = useState(false);

  // 스키마에서 필드들 추출
  const fields = useMemo(
    () => Object.keys((schema as any)._def.shape()),
    [schema]
  );

  // 현재 필드들의 값으로 validation 실행
  const validateFields = (data: Record<string, any>) => {
    const currentData = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: data[field],
      }),
      {}
    );

    const validationResult = schema.safeParse(currentData);
    setIsValid(validationResult.success);
  };

  // 스키마가 변경될 때 현재 값들로 즉시 validation 실행
  useEffect(() => {
    const subscription = watch((data) => {
      validateFields(data);
    });

    // 초기 validation 실행
    const initialData = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: watch(field as Path<TFormData>),
      }),
      {}
    );
    validateFields(initialData);

    // cleanup 함수에서 구독 해제
    return () => subscription.unsubscribe();
  }, [watch, schema, fields]);

  return isValid;
}
