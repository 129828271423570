import styled from 'styled-components';
import Layout from '../Layout';
import { media } from '@lib/constants/breakpoints';

export default function Section01() {
  return (
    <Layout>
      <StyledMain>
        <DesktopVideo autoPlay muted loop playsInline>
          <source
            src="https://careid.s3.ap-northeast-2.amazonaws.com/videos/xyz_Home.mp4"
            type="video/mp4"
          />
        </DesktopVideo>
        <MobileVideo autoPlay muted loop playsInline>
          <source
            src="https://careid.s3.ap-northeast-2.amazonaws.com/videos/xyz_Home_Mobile.mp4"
            type="video/mp4"
          />
        </MobileVideo>
        <MainInner>
          <MainContent>
            <MainTitle>
              옷에 필요한 모든 것,
              <br />
              CARE ID에서 손쉽게
            </MainTitle>
            <ButtonsContainer>
              {/* <StyledButton>
                <Link to="/login">로그인</Link>
              </StyledButton> */}
            </ButtonsContainer>
          </MainContent>
        </MainInner>
      </StyledMain>
    </Layout>
  );
}

const DesktopVideo = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  ${media.mobile} {
    display: none;
  }
`;

const MobileVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: none;

  ${media.mobile} {
    display: block;
  }
`;

const StyledMain = styled.main`
  margin-top: 128px;
  margin-bottom: 64px;
  height: calc(100vh - 256px);
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 40px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 30%;
    width: 150px;
    height: 100%;
    background: linear-gradient(to right, white, transparent);
    z-index: 1;
  }

  ${media.mobile} {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    border: none;
    border-radius: 0;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }
  }
`;

const MainInner = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;
`;

const MainContent = styled.div`
  align-items: flex-start;
  border-radius: 40px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 28px;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 0 80px;
  position: relative;
  width: 100%;

  ${media.tablet} {
    padding: 0 20px;
  }

  ${media.mobile} {
    justify-content: flex-start;
    padding: 100px 10px 0;
  }
`;

const MainTitle = styled.h1`
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 1px;

  ${media.tablet} {
    font-size: 48px;
  }

  ${media.mobile} {
    font-size: 32px;
  }
`;

const ButtonsContainer = styled.div`
  width: fit-content;
  display: flex;
  gap: 24px;
`;

const StyledButton = styled.button`
  width: 120px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  padding: 12px 12px 12px 16px;
  border-radius: 10px;
  font-weight: 500;
  transition: all 0.3s ease;

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Hovered */
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.black};

    a {
      color: inherit;
    }
  }

  ${media.mobile} {
    display: none;
  }
`;
