import { useCallback, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { UNITS } from '@lib/constants/admin/careLabel';
import { editingLayerIdAtom } from '@store/admin/careLabel';
import { Layer } from '@customTypes/admin/careLabel';
import { useLayerController } from './useLayerController';
import { pxToMm } from '@lib/utils';

interface TextController {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  editingLayerId: string;
  handleDoubleClick: (layer: Layer) => void;
  handleTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextBlur: () => void;
  handleKeyDown: (e: React.KeyboardEvent) => void;
}

export const useTextController = (pageId: string): TextController => {
  const { layers, toggleSelection, updateLayouts, updateLayerContent } =
    useLayerController(pageId);

  const [editingLayerId, setEditingLayerId] = useAtom(editingLayerIdAtom);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateTextLayerSize = useCallback(() => {
    if (!textAreaRef.current || !editingLayerId) return;

    const textarea = textAreaRef.current;

    // 스크롤 높이를 기준으로 필요한 높이 계산
    const scrollHeight = textarea.scrollHeight;
    const currentLayer = layers.find((l) => l.id === editingLayerId);

    if (!currentLayer) return;

    // 그리드 단위로 변환 (px -> mm)
    const newHeight = Math.round(pxToMm(scrollHeight));

    // 현재 높이보다 클 때만 업데이트
    if (currentLayer.layout.h < newHeight) {
      updateLayouts([
        {
          ...currentLayer.layout,
          i: editingLayerId,
          w: currentLayer.layout.w, // 너비는 유지
          h: newHeight,
        },
      ]);
    }
  }, [textAreaRef, editingLayerId, layers, updateLayouts]);

  // 텍스트 변경 시마다 크기 업데이트
  useEffect(() => {
    if (editingLayerId && textAreaRef.current) {
      updateTextLayerSize();
    }
  }, [textAreaRef, editingLayerId, updateTextLayerSize]);

  const handleDoubleClick = useCallback(
    (layer: Layer) => {
      if (layer.category === 'text') {
        toggleSelection(layer.id);
        setEditingLayerId(layer.id);
      }
    },
    [toggleSelection, setEditingLayerId]
  );

  const handleTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!editingLayerId) return;
      updateLayerContent(editingLayerId, e.target.value);
      // 내용이 변경될 때마다 크기 업데이트
      requestAnimationFrame(updateTextLayerSize);
    },
    [editingLayerId, updateLayerContent, updateTextLayerSize]
  );

  const handleTextBlur = useCallback(() => {
    setEditingLayerId('');
  }, [setEditingLayerId]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setEditingLayerId('');
      }
    },
    [setEditingLayerId]
  );

  return {
    editingLayerId,
    handleDoubleClick,
    handleTextChange,
    handleTextBlur,
    handleKeyDown,
    textAreaRef,
  };
};
