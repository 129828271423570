import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { allowScroll, preventScroll } from '@lib/utils';
import { CircleSpinner } from '.';

interface LoadingSpinnerProps {
  isMutating?: boolean;
  minTimeout?: number;
}

export default function LoadingSpinner({
  isMutating = false,
  minTimeout = 200,
}: LoadingSpinnerProps) {
  const [loading, setLoading] = useState<boolean>(false);

  // 스크롤 방지
  useEffect(() => {
    const prevScrollY = preventScroll();

    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  // 200ms 이상 소요될 때만 렌더링
  useEffect(() => {
    const timeoutId = setTimeout(() => setLoading(true), minTimeout);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <Container $modalBg={isMutating}>
      <CircleSpinner />
    </Container>
  );
}

interface ContainerProps {
  $modalBg?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$modalBg ? props.theme.colors.modalBg : props.theme.colors.white};
  overflow: hidden;
  z-index: 9997;
`;
