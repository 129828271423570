import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { pxToMm } from '@lib/utils';
import { selectedToolAtom } from '@store/admin/careLabel';
import { QrData, QrType, QrUpdateParams } from '@customTypes/admin';
import { useLayerController } from './useLayerController';
import { useLabelFormat } from './useLabelFormat';

export const useQrController = (pageId: string) => {
  const [, setSelectedTool] = useAtom(selectedToolAtom);
  const { center } = useLabelFormat();
  const {
    layers,
    selectedLayers,
    addLayer,
    updateQrData,
    getLayerById,
    toggleSelection,
  } = useLayerController(pageId);

  const getSelectedQrLayer = useCallback(() => {
    if (selectedLayers.length !== 1) return;

    const layer = getLayerById(selectedLayers[0]);
    if (!layer || layer.category !== 'qr') return;

    return layer;
  }, [selectedLayers, getLayerById]);

  const updateSelectedQrLayer = useCallback(
    (updates: QrUpdateParams) => {
      const layer = getSelectedQrLayer();
      if (!layer) return;

      const newQrData: QrData = {
        type: updates.type || layer?.qrData?.type || 'url',
        url:
          updates.type === 'dpp'
            ? undefined
            : (updates.url ?? layer?.qrData?.url ?? ''),
      };

      updateQrData(layer.id, newQrData);
    },
    [getSelectedQrLayer, updateQrData]
  );

  const handleQrAdd = useCallback(() => {
    const w = 10 + pxToMm(4);
    const h = 10 + pxToMm(4);

    const layerId = addLayer({
      category: 'qr',
      layout: {
        x: center.x - w / 2,
        y: center.y - h / 2,
        w,
        h,
      },
      qrData: {
        type: '',
      },
    });

    toggleSelection(layerId);

    setSelectedTool('select');
  }, [addLayer, center, setSelectedTool, toggleSelection]);

  const handleQrTypeChange = useCallback(
    (type: QrType) => {
      updateSelectedQrLayer({ type });
    },
    [updateSelectedQrLayer]
  );

  const handleQrUrlChange = useCallback(
    (url: string) => {
      const layer = getSelectedQrLayer();
      if (!layer) return;

      const type = layer.qrData?.type;
      if (!type || type !== 'url') {
        console.error('QR 유형이 외부 URL일 경우에만 사용 가능합니다.');
        return false;
      }

      updateSelectedQrLayer({ url });
    },
    [getSelectedQrLayer, updateSelectedQrLayer]
  );

  return {
    getSelectedQrLayer,
    handleQrAdd,
    handleQrTypeChange,
    handleQrUrlChange,
  };
};
