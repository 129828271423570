type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

export const DEFAULT_RESIZE_HANDLES: ResizeHandle[] = [
  'sw',
  'nw',
  'se',
  'ne',
  // 's',
  // 'w',
  // 'e',
  // 'n',
];
export const LINE_RESIZE_HANDLES: ResizeHandle[] = ['w', 'e'];
