import { CSSProperties } from 'react';
import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { v4 as uuidv4 } from 'uuid';
import { calculateAlignPosition } from '@lib/utils';
import { DEFAULT_LAYER_CONFIGS } from '@lib/constants/admin/careLabel';
import {
  currentPageIdAtom,
  careLabelDesignJsonAtom,
  selectedToolAtom,
} from '@store/admin/careLabel';
import {
  AlignDirection,
  CareLabelPage,
  GridItemLayout,
  Layer,
  LayerCategory,
  QrData,
  QrType,
  SelectedLayers,
} from '@customTypes/admin/careLabel';

// // 히스토리 상태를 저장할 타입 정의
// // redo/undo 기능을 위해 필요
// interface LayerHistory {
//   past: Layer[][];
//   present: Layer[];
//   future: Layer[][];
// }

// // 페이지별 히스토리 상태 관리
// export const createLayerHistoryAtom = (pageId: string) =>
//   atomWithStorage<LayerHistory>(`layer-history-${pageId}`, {
//     past: [],
//     present: [],
//     future: [],
//   });

// const historyCache = new Map<
//   string,
//   ReturnType<typeof createLayerHistoryAtom>
// >();

// export const getLayerHistoryAtom = (pageId: string) => {
//   if (!historyCache.has(pageId)) {
//     historyCache.set(pageId, createLayerHistoryAtom(pageId));
//   }
//   return historyCache.get(pageId)!;
// };

// 히스토리 상태를 저장할 타입 정의
interface LayerHistory {
  past: Record<string, Layer[]>[]; // 각 시점의 모든 페이지 레이어 상태
  present: Record<string, Layer[]>; // 현재 모든 페이지 레이어 상태
  future: Record<string, Layer[]>[]; // 미래의 모든 페이지 레이어 상태
}

// 히스토리 초기 상태
const initialHistory: LayerHistory = {
  past: [],
  present: {},
  future: [],
};

// 전체 페이지의 히스토리 상태 관리
export const layerHistoryAtom = atomWithStorage<LayerHistory>(
  'layer-history',
  initialHistory
);

// id를 키로 사용하여 각 LabelPage의 상태를 독립적으로 저장
type LayersAtom = ReturnType<typeof atomWithStorage<Layer[]>>;
// layouts 상태 추가 필요

// 페이지별 레이어 상태 관리
export const createLayersAtom = (pageId: string): LayersAtom =>
  atomWithStorage<Layer[]>(`layers-${pageId}`, []);

// atoms 캐시
const atomsCache = new Map<string, LayersAtom>();

export const updateLayersAtom = (
  pageId: string,
  layers: Layer[]
): LayersAtom => {
  const atom = atomWithStorage<Layer[]>(`layers-${pageId}`, layers);
  atomsCache.set(pageId, atom);

  return atom;
};

export const getLayersAtom = (pageId: string): LayersAtom => {
  if (!atomsCache.has(pageId)) {
    atomsCache.set(pageId, createLayersAtom(pageId));
  }
  return atomsCache.get(pageId)!;
};

// 정리 함수
export const clearAllLayerState = () => {
  // atoms 캐시 전체 삭제
  atomsCache.clear();

  // localStorage에서 모든 레이어 관련 데이터 삭제
  for (const key of Object.keys(localStorage)) {
    if (key.includes('layer')) {
      localStorage.removeItem(key);
    }
  }

  // 히스토리 초기화
  localStorage.setItem('layer-history', JSON.stringify(initialHistory));
};

// 레이어들의 레이아웃만 관리
export const getLayerLayoutsAtom = (pageId: string) =>
  atom<GridItemLayout[]>((get) => {
    return get(getLayersAtom(pageId)).map((layer) => layer.layout);
  });

const initSelectedLayers = {
  pageId: '',
  layerIds: [],
};

// 선택된 레이어 목록은 공용으로 관리
export const selectedLayersAtom = atom<SelectedLayers>(initSelectedLayers);
selectedLayersAtom.onMount = (setAtom) => {
  return () => {
    setAtom(initSelectedLayers);
  };
};

export const copiedLayersAtom = atom<Layer[]>([]);
copiedLayersAtom.onMount = (setAtom) => {
  return () => {
    setAtom([]);
  };
};

export const originalLayersForCloneAtom = atom<Layer[]>([]);
originalLayersForCloneAtom.onMount = (setAtom) => {
  return () => {
    setAtom([]);
  };
};

// 텍스트 편집 중인 레이어의 ID
export const editingLayerIdAtom = atom<string>('');
editingLayerIdAtom.onMount = (setAtom) => {
  return () => {
    setAtom('');
  };
};

interface CreateLayerParams {
  existingLayers: Layer[];
  category: LayerCategory;
  layout?: Partial<GridItemLayout>;
  style?: Partial<CSSProperties>;
  src?: string;
  content?: string;
  qrData?: QrData;
  key?: string;
}

// 새로운 레이어 생성해주는 클래스
export class LayerFactory {
  static createLayer({
    existingLayers,
    category,
    layout,
    style,
    src,
    content,
    qrData,
    key,
  }: CreateLayerParams): Layer {
    const newId = uuidv4();
    const defaultConfig = DEFAULT_LAYER_CONFIGS[category];
    const newLayout = { ...defaultConfig.layout, i: newId, ...layout };
    const newStyle = { ...defaultConfig.style, ...style };
    const lastZIndex = existingLayers.at(-1)?.zIndex;

    return {
      id: newId,
      type: defaultConfig.type,
      category,
      layout: newLayout,
      style: newStyle,
      zIndex: lastZIndex ? lastZIndex + 1 : 1,
      isGroup: false,
      ...(defaultConfig.content && { content: defaultConfig.content }),
      ...(content && { content }),
      ...(src && { src }),
      ...(qrData && { qrData }),
      // category가 variable일 때만 key 추가
      ...(key && { key }),
    };
  }
}

const getAllPagesLayers = (get: any) => {
  const designData = get(careLabelDesignJsonAtom);
  const allLayers: Record<string, Layer[]> = {};

  // 각 페이지의 레이어 상태를 수집
  designData.pages.forEach((page: CareLabelPage) => {
    allLayers[page.id] = get(getLayersAtom(page.id));
  });

  return allLayers;
};

interface AddLayerParams {
  category: LayerCategory;
  layout?: Partial<GridItemLayout>;
  src?: string;
  content?: string;
  qrData?: QrData;
  key?: string;
}

// id별 actions 생성 함수
export const createLayerActions = (pageId: string) => {
  const layersAtom = getLayersAtom(pageId);
  // const historyAtom = getLayerHistoryAtom(pageId);

  const actions = {
    // 상태 변경 전 히스토리에 현재 상태 저장하는 유틸리티 함수
    saveToHistory: atom(null, (get, set) => {
      const history = get(layerHistoryAtom);
      const allPageLayers = getAllPagesLayers(get);

      // 과거 히스토리 개수 제한
      const MAX_HISTORY = 10; // 더 작은 값으로 조정
      let pastHistory =
        Object.keys(history.present).length > 0
          ? [...history.past, history.present]
          : history.past;

      // 용량 초과를 방지하기 위해 미리 히스토리 크기 제한
      pastHistory = pastHistory.slice(-MAX_HISTORY);

      const newHistory = {
        past: pastHistory,
        present: allPageLayers,
        future: [],
      };

      try {
        set(layerHistoryAtom, newHistory);
      } catch (error) {
        console.warn('History storage failed:', error);

        // 에러 발생 시 히스토리 초기화
        set(layerHistoryAtom, {
          past: [],
          present: allPageLayers,
          future: [],
        });
      }
    }),

    undo: atom(null, (get, set) => {
      const history = get(layerHistoryAtom);
      if (history.past.length === 0) return;

      const previousState = history.past[history.past.length - 1];

      const newHistory = {
        past: history.past.slice(0, -1),
        present: previousState,
        future: [history.present, ...history.future],
      };
      set(layerHistoryAtom, newHistory);

      Object.entries(previousState).forEach(([pageId, layers]) => {
        set(getLayersAtom(pageId), layers);
      });
    }),

    // Redo 액션
    redo: atom(null, (get, set) => {
      const history = get(layerHistoryAtom);
      console.log(history);

      if (history.future.length === 0) return;

      const nextState = history.future[0];
      const newFuture = history.future.slice(1);
      set(layerHistoryAtom, {
        past: [...history.past, history.present],
        present: nextState,
        future: newFuture,
      });

      // 각 페이지의 레이어 상태 업데이트
      Object.entries(nextState).forEach(([pageId, layers]) => {
        set(getLayersAtom(pageId), layers);
      });
    }),

    // 레이어 생성하는 writable atom
    addLayer: atom(
      null,
      (
        get,
        set,
        { category, layout, src, content, qrData, key }: AddLayerParams
      ) => {
        const currentLayers = get(layersAtom);

        // 새 레이어 생성
        const newLayer = LayerFactory.createLayer({
          existingLayers: currentLayers,
          category,
          layout,
          src,
          content,
          qrData,
          key,
        });

        // 먼저 새 레이어 추가
        const updatedLayers = [...currentLayers, newLayer];
        set(layersAtom, updatedLayers);

        // 그 다음 히스토리에 저장
        set(actions.saveToHistory);

        return newLayer.id;
      }
    ),

    // 레이어 선택하는 writable atom
    toggleLayerSelection: atom(
      null,
      (get, set, layerId?: string, multiSelect?: boolean) => {
        // pageId 설정
        set(currentPageIdAtom, pageId);

        if (!layerId) {
          // 선택 해제
          set(selectedLayersAtom, { pageId, layerIds: [] });
          return;
        }

        set(selectedToolAtom, 'select');

        if (!multiSelect) {
          // 단일 선택
          set(selectedLayersAtom, { pageId, layerIds: [layerId] });
          return;
        }

        const currentSelection = get(selectedLayersAtom);

        // 다른 페이지의 레이어가 선택되어 있었다면 새로운 선택으로 교체
        if (currentSelection.pageId !== pageId) {
          set(selectedLayersAtom, { pageId, layerIds: [layerId] });
          return;
        }

        // 같은 페이지 내에서 다중 선택
        set(selectedLayersAtom, {
          pageId,
          layerIds: currentSelection.layerIds.includes(layerId)
            ? currentSelection.layerIds.filter((id) => id !== layerId)
            : [...currentSelection.layerIds, layerId],
        });
      }
    ),

    // 선택된 레이어 모두 선택 해제하는 writable atom
    clearSelectedLayers: atom(null, (get, set) => {
      set(selectedLayersAtom, { pageId, layerIds: [] });
    }),

    // 선택된 레이어(들) 복사하는 writable atom
    copySelectedLayers: atom(null, (get, set) => {
      const currentSelection = get(selectedLayersAtom);
      const layers = get(layersAtom);
      const layersCopied = layers.filter((layer) =>
        currentSelection.layerIds.includes(layer.id)
      );

      set(copiedLayersAtom, layersCopied);
    }),

    // 선택된 레이어(들) 붙여넣기하는 writable atom
    pasteSelectedLayers: atom(null, (get, set) => {
      if (get(currentPageIdAtom) !== pageId) return;

      const layers = get(layersAtom);
      const layersToPaste = get(copiedLayersAtom);
      const newLayers = layersToPaste.map((layer) =>
        LayerFactory.createLayer({
          existingLayers: layers,
          category: layer.category,
          layout: layer.layout,
          style: layer.style,
          src: layer.src,
          content: layer.content,
        })
      );

      set(layersAtom, [...layers, ...newLayers]);
      set(selectedLayersAtom, {
        pageId,
        layerIds: [...newLayers.map((layer) => layer.id)],
      });
    }),

    // 선택된 레이어 복제하는 writable atom
    cloneSelectedLayers: atom(null, (get, set) => {
      if (get(currentPageIdAtom) !== pageId) return;

      const layers = get(layersAtom);
      const currentSelection = get(selectedLayersAtom);
      const layersToClone = layers.filter((layer) =>
        currentSelection.layerIds.includes(layer.id)
      );

      set(originalLayersForCloneAtom, layersToClone);

      const newLayers = layersToClone.map((layer) =>
        LayerFactory.createLayer({
          existingLayers: layers,
          category: layer.category,
          layout: layer.layout,
          style: layer.style,
          src: layer.src,
          content: layer.content,
        })
      );

      set(layersAtom, [...layers, ...newLayers]);
    }),

    setTempOriginalLayers: atom(null, (get, set, layers: Layer[]) => {
      set(originalLayersForCloneAtom, layers);
    }),

    removeTempOriginalLayers: atom(null, (get, set) => {
      set(originalLayersForCloneAtom, []);
    }),

    // 선택된 레이어 삭제하는 writable atom
    removeSelectedLayers: atom(null, (get, set) => {
      const currentSelection = get(selectedLayersAtom);

      // 현재 페이지의 선택된 레이어만 삭제
      if (currentSelection.pageId === pageId) {
        set(layersAtom, (prev) =>
          prev.filter((layer) => !currentSelection.layerIds.includes(layer.id))
        );
        // 선택 상태 초기화
        set(selectedLayersAtom, { pageId: '', layerIds: [] });
      }

      // 현재 상태를 히스토리에 저장
      set(actions.saveToHistory);
    }),

    // 레이어 ID로 레이어 찾는 writable atom
    getLayerById: atom(null, (get, set, layerId: string) => {
      const layers = get(layersAtom);
      return layers.find((layer) => layer.id === layerId);
    }),

    // 레이어의 레이아웃을 업데이트하는 writable atom
    updateLayerLayouts: atom(null, (get, set, layouts: GridItemLayout[]) => {
      set(layersAtom, (prev) =>
        prev.map((layer) => ({
          ...layer,
          layout:
            layouts.find((layout) => layout.i === layer.id) || layer.layout,
        }))
      );

      // 현재 상태를 히스토리에 저장
      set(actions.saveToHistory);
    }),

    // 레이어 정렬하는 writable atom
    alignLayers: atom(null, (get, set, direction: AlignDirection) => {
      const currentSelection = get(selectedLayersAtom);
      const layers = get(layersAtom);
      // const settingData = get(careLabelSettingDataAtom);
      const designData = get(careLabelDesignJsonAtom);

      // 선택된 레이어가 없으면 종료
      if (currentSelection.layerIds.length === 0) return;

      // 선택된 레이어들 찾기
      const selectedLayers = layers.filter((layer) =>
        currentSelection.layerIds.includes(layer.id)
      );

      // 새로운 위치 계산
      const newPositions = calculateAlignPosition(
        selectedLayers,
        direction,
        designData.settings
      );

      // 레이어 업데이트
      set(layersAtom, (prev) =>
        prev.map((layer) => {
          const newPosition = newPositions[layer.id];
          if (!newPosition) return layer;

          return {
            ...layer,
            layout: {
              ...layer.layout,
              ...newPosition,
            },
          };
        })
      );

      // 현재 상태를 히스토리에 저장
      set(actions.saveToHistory);
    }),

    // 레이어 content 업데이트하는 writable atom
    updateLayerContent: atom(
      null,
      (get, set, layerId: string, content: string) => {
        set(layersAtom, (prev) =>
          prev.map((layer) =>
            layer.id === layerId ? { ...layer, content } : layer
          )
        );

        // 현재 상태를 히스토리에 저장
        // 상태 변경이 완료된 후에만 히스토리 저장
        setTimeout(() => {
          set(actions.saveToHistory);
        }, 0);
      }
    ),

    updateLayerImageSrc: atom(
      null,
      (get, set, layerId: string, imageUrl: string) => {
        set(layersAtom, (prev) =>
          prev.map((layer) =>
            layer.id === layerId ? { ...layer, src: imageUrl } : layer
          )
        );
      }
    ),

    updateQrData: atom(null, (get, set, layerId: string, qrData: QrData) => {
      set(layersAtom, (prev) =>
        prev.map((layer) =>
          layer.id === layerId
            ? {
                ...layer,
                qrData,
              }
            : layer
        )
      );

      // 현재 상태를 히스토리에 저장
      // 상태 변경이 완료된 후에만 히스토리 저장
      setTimeout(() => {
        set(actions.saveToHistory);
      }, 0);
    }),

    // 레이어 content 업데이트하는 writable atom
    updateLayerStyle: atom(
      null,
      (
        get,
        set,
        layerId: string,
        styleKey: string,
        styleValue: string | number
      ) => {
        set(layersAtom, (prev) =>
          prev.map((layer) =>
            layer.id === layerId
              ? { ...layer, style: { ...layer.style, [styleKey]: styleValue } }
              : layer
          )
        );

        // 현재 상태를 히스토리에 저장
        set(actions.saveToHistory);
      }
    ),

    addLayerStyle: atom(
      null,
      (
        get,
        set,
        layerId: string,
        styleKey: keyof CSSProperties,
        styleValue: string | number
      ) => {
        set(layersAtom, (prev) =>
          prev.map((layer) =>
            layer.id === layerId
              ? {
                  ...layer,
                  style: {
                    ...layer.style,
                    [styleKey]:
                      (layer.style?.[styleKey]
                        ? layer.style[styleKey] + ' '
                        : '') + styleValue,
                  },
                }
              : layer
          )
        );
      }
    ),

    // 레이어 목록 초기화하는 writable atom (레이어 모두 삭제)
    initLayers: atom(null, (get, set) => {
      // storage에서 해당 페이지의 레이어 데이터 삭제
      localStorage.removeItem(`layers-${pageId}`);
      // atom 초기화
      set(layersAtom, []);
      // 해당 페이지의 선택된 레이어도 초기화
      const currentSelection = get(selectedLayersAtom);
      if (currentSelection.pageId === pageId) {
        set(selectedLayersAtom, { pageId: '', layerIds: [] });
      }
    }),
  };

  return actions;
};

// 기존 디자인 데이터와 현재 디자인모드 내 디자인 데이터 동기화
export const syncPagesLayersAtom = atom(
  (get) => {
    // read operation: 현재 페이지들의 레이어 상태를 가져옴
    const designData = get(careLabelDesignJsonAtom);
    return designData.pages?.map((page) => ({
      ...page,
      layers: get(getLayersAtom(page.id)),
    }));
  },
  (get, set, pages: CareLabelPage[]) => {
    // write operation: 페이지들의 레이어 상태를 설정
    pages.forEach((page) => {
      set(getLayersAtom(page.id), page.layers);
    });
  }
);
