import { useCallback, useState } from 'react';

export default function useHover() {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleHovered = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleUnhovered = useCallback(() => {
    setIsHovered(false);
  }, []);

  return { isHovered, handleHovered, handleUnhovered };
}
