import styled, { CSSProperties } from 'styled-components';
import { Icon, IconName } from '../Icon';

interface IconButtonProps extends CSSProperties {
  iconName: IconName;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  disabled?: boolean;
}

export default function IconButton({
  iconName,
  type = 'button',
  onClick,
  disabled,
}: IconButtonProps) {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      <Icon name={iconName} fill={disabled ? 'gray200' : 'black'} />
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};

  /* Disabled */
  &:disabled {
    color: ${(props) => props.theme.colors.gray200};
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.white};
    cursor: default;
  }

  /* Hovered */
  &:not(:disabled):hover {
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.gray100};
  }

  /* Clicked */
  &:not(:disabled):active {
    color: ${(props) => props.theme.colors.gray500};
    border-color: ${(props) => props.theme.colors.gray100};
    background-color: ${(props) => props.theme.colors.white};
  }
`;
