import { AlignType } from '@customTypes/admin/careLabel/align';

export const HORIZONTAL_ALIGN_BUTTONS: AlignType[] = [
  { value: 'left', label: '왼쪽', iconName: 'AlignLeft' },
  { value: 'center', label: '가운데', iconName: 'AlignCenter' },
  { value: 'right', label: '오른쪽', iconName: 'AlignRight' },
];

export const VERTICAL_ALIGN_BUTTONS: AlignType[] = [
  { value: 'top', label: '상단', iconName: 'AlignTop' },
  { value: 'middle', label: '중간', iconName: 'AlignMiddle' },
  { value: 'bottom', label: '하단', iconName: 'AlignBottom' },
];
