import { atom } from 'jotai';
import { ProductSizeSchemaType } from '@lib/apis/admin/@schema';
import { INIT_SIZE_FIELDS } from '@lib/constants/admin/careLabel/new';
import { ProductSizeType } from '@customTypes/admin';

export const REQUIRED_SIZE_FIELD_LIST: ProductSizeType[] = [
  'size_name',
  'size_value',
];

export const selectedSizeFieldListAtom = atom<ProductSizeType[]>(
  REQUIRED_SIZE_FIELD_LIST
);

export const sizeFieldObjAtom = atom((get) => {
  const selectedFields = get(selectedSizeFieldListAtom);

  return selectedFields.reduce((acc, field) => {
    acc[field] = '';
    return acc;
  }, INIT_SIZE_FIELDS as ProductSizeSchemaType);
});
