import { useMemo } from 'react';
import { UseFormWatch, FieldErrors } from 'react-hook-form';
import { CareLabelSizeAndTemplateSchemaType } from '@lib/apis/admin/@schema';
import { FieldMessage, FormFieldsMessages } from '@customTypes/admin';

type CareLabelSizeTemplateMessages =
  FormFieldsMessages<CareLabelSizeAndTemplateSchemaType>;

export function useCareLabelSizeTemplateMessages(
  watch: UseFormWatch<CareLabelSizeAndTemplateSchemaType>,
  errors: FieldErrors<CareLabelSizeAndTemplateSchemaType>
): CareLabelSizeTemplateMessages {
  return useMemo(
    () => ({
      option: getBasicFieldMessage(
        errors.option?.message,
        '접지 방식을 선택해주세요.'
      ),
      width: getBasicFieldMessage(
        errors.width?.message,
        '양수의 정수만 입력 가능합니다.'
      ),
      height: getBasicFieldMessage(
        errors.height?.message,
        '최소 32mm, 최대 240mm'
      ),
      seam_margin: getBasicFieldMessage(
        errors.seam_margin?.message,
        '최소 10mm 이상'
      ),
      side_margin: getBasicFieldMessage(
        errors.side_margin?.message,
        '최소 1mm 이상'
      ),
      template_code: getBasicFieldMessage(errors.template_code?.message),
    }),
    [
      errors.option,
      errors.width,
      errors.height,
      errors.seam_margin,
      errors.side_margin,
      errors.template_code,
    ]
  );
}

// 기본 필드 메시지
const getBasicFieldMessage = (
  errorMessage?: string,
  defaultGuide?: string
): FieldMessage => {
  const hasError = errorMessage && errorMessage !== 'Required';

  return {
    type: hasError ? 'error' : 'default',
    text: hasError ? errorMessage : defaultGuide || '',
  };
};
