import styled, { CSSProperties } from 'styled-components';

interface ButtonProps extends CSSProperties {
  label: string | JSX.Element;
  onClickBtn?: () => void;
  disabled?: boolean;
}

export default function Button({
  label,
  onClickBtn,
  disabled,
  ...styles
}: ButtonProps) {
  return (
    <StyledButton
      type={onClickBtn ? 'button' : 'submit'}
      disabled={!!disabled}
      {...(onClickBtn && { onClick: onClickBtn })}
      style={{ ...styles }}
    >
      <span>{label}</span>
    </StyledButton>
  );
}

interface StyledButtonProps {
  customStyles?: React.CSSProperties;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 10px;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.black};
  ${(props) => props.customStyles && { ...props.customStyles }};

  &:disabled {
    color: ${(props) => props.theme.colors.gray400};
    background-color: ${(props) => props.theme.colors.gray100};
    cursor: default;
  }
`;
