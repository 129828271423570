import { NullishRole, Role } from '@customTypes/admin';
import { Nav } from '@lib/constants/admin/common';
import { getNavListByRole } from '@lib/utils/navigator';
import { atom } from 'jotai';

export const accessTokenAtom = atom<string>(
  localStorage.getItem('accessToken') ?? ''
);

export const accessTokenAtomWithPersistence = atom(
  (get) => get(accessTokenAtom),
  (get, set, newAccessToken?: string) => {
    if (newAccessToken) {
      set(accessTokenAtom, newAccessToken);
      localStorage.setItem('accessToken', newAccessToken);
    } else {
      set(accessTokenAtom, '');
      localStorage.removeItem('accessToken');
    }
  }
);

export const roleAtom = atom<NullishRole>(
  localStorage.getItem('role') as NullishRole
);

export const roleAtomWithPersistence = atom(
  (get) => get(roleAtom),
  (get, set, newRole?: NullishRole) => {
    if (newRole) {
      set(roleAtom, newRole);
      localStorage.setItem('role', newRole);
    } else {
      set(roleAtom, undefined);
      localStorage.removeItem('role');
    }
  }
);

export const navListByRoleAtom = atom<Nav[]>((get) => {
  const role = get(roleAtom);

  return getNavListByRole(role);
});

export const chargeBrandAtom = atom<string>(
  localStorage.getItem('chargeBrand') ?? ''
);

export const chargeBrandAtomWithPersistence = atom(
  (get) => get(chargeBrandAtom),
  (get, set, newChargeBrand?: string) => {
    if (newChargeBrand) {
      set(chargeBrandAtom, newChargeBrand);
      localStorage.setItem('chargeBrand', newChargeBrand);
    } else {
      set(chargeBrandAtom, '');
      localStorage.removeItem('chargeBrand');
    }
  }
);

export const emailAtom = atom<string>(localStorage.getItem('email') as string);

export const emailAtomWithPersistence = atom(
  (get) => get(emailAtom),
  (get, set, newEmail?: string) => {
    if (newEmail) {
      set(emailAtom, newEmail);
      localStorage.setItem('email', newEmail);
    } else {
      set(emailAtom, '');
      localStorage.removeItem('email');
    }
  }
);
