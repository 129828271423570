import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { scrollYPosAtom } from '@store/scroll';

interface UseModalProps {
  openOnMount?: boolean;
}

export default function useModal({ openOnMount }: UseModalProps = {}) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [scrollY, setScrollY] = useAtom(scrollYPosAtom);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    setIsModalOpen(!!openOnMount);
  }, [openOnMount]);

  useEffect(() => {
    if (isModalOpen || scrollY <= 0) return;

    window.scrollTo(0, scrollY);
  }, [isModalOpen, scrollY]);

  useEffect(() => {
    return () => {
      setScrollY(0);
    };
  }, []);

  return { isModalOpen, handleModalOpen, handleModalClose };
}
