import { useNavigate } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';
import { LOGO_IMAGE_BLACK_URL } from '@lib/constants/imageUrls';

interface LogoButtonProps extends CSSProperties {
  onClick?: () => void;
}

export default function LogoButton({ onClick, ...styles }: LogoButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    navigate('/');
  };

  return (
    <LogoImageButton type="button" onClick={handleClick} style={styles}>
      <img src={LOGO_IMAGE_BLACK_URL} alt="CARE ID" />
    </LogoImageButton>
  );
}

const LogoImageButton = styled.button`
  /* padding: 8px 20px; */
  height: 100%;
  display: flex;
  align-items: center;

  & > img {
    height: 20px;
    width: auto;
  }
`;
