import { ProductSizeField, ProductSizeType } from '@customTypes/admin';
import { ProductSizeSchemaType } from '@lib/apis/admin/@schema';

export const INIT_SIZE_FIELDS: ProductSizeSchemaType = {
  size_name: '',
  size_value: '',
};

export const SIZE_FIELD_LIST: ProductSizeField[] = [
  {
    label: '사이즈 호칭',
    name: 'size_name',
    placeholder: '예시) S',
    required: true,
  },
  {
    label: '사이즈 값',
    name: 'size_value',
    placeholder: '예시) 90',
    required: true,
  },
  {
    label: '총 기장',
    name: 'size_outseam',
    placeholder: '예시) 70cm',
    required: false,
  },
  {
    label: '어깨 너비',
    name: 'size_shoulder_width',
    placeholder: '예시) 48cm',
    required: false,
  },
  {
    label: '가슴 단면',
    name: 'size_chest_width',
    placeholder: '예시) 54cm',
    required: false,
  },
  {
    label: '소매 길이',
    name: 'size_sleeve_length',
    placeholder: '예시) 63cm',
    required: false,
  },
  {
    label: '허리 단면',
    name: 'size_waist_width',
    placeholder: '예시) 70cm',
    required: false,
  },
  {
    label: '밑위',
    name: 'size_raise_length',
    placeholder: '예시) 10cm',
    required: false,
  },
  {
    label: '엉덩이 단면',
    name: 'size_back_rise',
    placeholder: '예시) 10cm',
    required: false,
  },
  {
    label: '허벅지 단면',
    name: 'size_thigh_width',
    placeholder: '예시) 20cm',
    required: false,
  },
  {
    label: '밑단 단면',
    name: 'size_leg_opening',
    placeholder: '예시) 10cm',
    required: false,
  },
];
