const ICON_STORAGE_BASE_URL = 'https://img.careid.xyz/icons';

export const ADD_ICON_URL = `${ICON_STORAGE_BASE_URL}/add-icon-white.svg`;

export const ARROW_LEFT_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-left-2.svg`;

export const ARROW_RIGHT_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-right-2.svg`;
export const ARROW_RIGHT_ICON_BRIGHT_URL = `${ICON_STORAGE_BASE_URL}/keyboard_arrow_right.svg`;

export const REFRESH_ICON_URL = `${ICON_STORAGE_BASE_URL}/refresh-icon.svg`;

// CarbonInfo gif 아이콘들
export const WATER_ICON_URL = `${ICON_STORAGE_BASE_URL}/water-icon.svg`;
export const TREE_ICON_URL = `${ICON_STORAGE_BASE_URL}/tree-icon.svg`;
export const BATTERY_ICON_URL = `${ICON_STORAGE_BASE_URL}/Battery-new.svg`;
export const CAR_ICON_URL = `${ICON_STORAGE_BASE_URL}/Vehicle-new.svg`;

// Admin Side Bar 아이콘들
export const HEART_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3DBrand.svg`;
export const CLOTHES_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3DProduct-Image.svg`;
export const LABEL_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3DCareLabel-Image.svg`;
export const INFO_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3DDPP.svg`;
export const LABEL_ORDER_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3DLabel-List.svg`;

// Admin Top Bar 아이콘
export const POWER_ICON_URL = `${ICON_STORAGE_BASE_URL}/power-icon.svg`;

export const PLUS_ICON_URL = `${ICON_STORAGE_BASE_URL}/plus-02.svg`;

export const REFRESH_ICON_2_URL = `${ICON_STORAGE_BASE_URL}/refresh-02.svg`;

export const SEARCH_ICON_URL = `${ICON_STORAGE_BASE_URL}/search-icon.svg`;

// 체크박스
export const CHECKBOX_DEFAULT_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3Dcheck-Default.svg`;

export const CHECKBOX_HOVERED_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3Dcheck-Hovered.svg`;

export const CHECKBOX_SELECTED_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3Dcheck-Selected.svg`;

export const EDIT_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3Dedit.svg`;

export const DELETE_ICON_URL = `${ICON_STORAGE_BASE_URL}/Icon%3Dtrash.svg`;

export const CHEVRON_LEFT_ICON_URL = `${ICON_STORAGE_BASE_URL}/chevron-left.svg`;

export const CHEVRON_RIGHT_ICON_URL = `${ICON_STORAGE_BASE_URL}/chevron-right.svg`;

export const CHEVRON_DOWN_ICON_URL = `${ICON_STORAGE_BASE_URL}/chevron-down.svg`;

// Image Uploader
export const PICTURE_ICON_URL = `${ICON_STORAGE_BASE_URL}/picture-icon.svg`;
// File Uploade
export const FILE_UPLOAD_ICON_URL = `${ICON_STORAGE_BASE_URL}/file-upload-icon.svg`;

export const MAGNIFIER_ICON_URL = `${ICON_STORAGE_BASE_URL}/magnifier-icon.svg`;

export const ADD_BOX_ICON_URL = `${ICON_STORAGE_BASE_URL}/add-box-icon.svg`;

export const EMAIL_ICON_URL = `${ICON_STORAGE_BASE_URL}/email-icon.svg`;

export const PHONE_ICON_URL = `${ICON_STORAGE_BASE_URL}/phone-icon.svg`;

export const WARNING_ICON_URL = `${ICON_STORAGE_BASE_URL}/warning-icon--gray-outline.svg`;

export const SEARCH_ICON_WHITE_URL = `${ICON_STORAGE_BASE_URL}/search-icon-1.svg`;

export const GLOBE_ICON_URL = `${ICON_STORAGE_BASE_URL}/globe-icon.svg`;

export const TRASH_CAN_ICON_RED_URL = `${ICON_STORAGE_BASE_URL}/trash-can-icon-red.svg`;

export const DOWNLOAD_ICON_URL = `${ICON_STORAGE_BASE_URL}/download-icon.svg`;

export const CALENDAR_ICON_URL = `${ICON_STORAGE_BASE_URL}/calender-icon.svg`;

export const SORT_ICON_URL = `${ICON_STORAGE_BASE_URL}/sort-icon.svg`;

export const CLOSE_ICON_GRAY_URL = `${ICON_STORAGE_BASE_URL}/close-gray-icon.svg`;

// 엑셀 업로드 문서 아이콘
export const DOCUMENT_ICON_URL = `${ICON_STORAGE_BASE_URL}/document-icon.svg`;

export const INDICATOR_PREV_ICON_URL = `${ICON_STORAGE_BASE_URL}/Indicator-left.svg`;
export const INDICATOR_NEXT_ICON_URL = `${ICON_STORAGE_BASE_URL}/Indicator-right.svg`;
export const INDICATOR_PREV_DOUBLE_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-double-left.svg`;
export const INDICATOR_NEXT_DOUBLE_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-double-right.svg`;

export const PICK_UP_ONBOARDING_STEP_ONE_ICON_URL = `${ICON_STORAGE_BASE_URL}/pickup-onboarding-icon-1.svg`;
export const PICK_UP_ONBOARDING_STEP_TWO_ICON_URL = `${ICON_STORAGE_BASE_URL}/pickup-onboarding-icon-2.svg`;
export const PICK_UP_ONBOARDING_STEP_THREE_ICON_URL = `${ICON_STORAGE_BASE_URL}/pickup-onboarding-icon-3.svg`;
export const PICK_UP_ONBOARDING_STEP_FOUR_ICON_URL = `${ICON_STORAGE_BASE_URL}/pickup-onboarding-icon-4.svg`;

export const ARROW_RIGHT_GRAY200_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-right-gray200.svg`;
export const ARROW_DOWN_GRAY400_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-down-gray400.svg`;
export const COUNT_PLUS_ICON_URL = `${ICON_STORAGE_BASE_URL}/count-plus-gray400.svg`;
export const COUNT_MINUS_ICON_URL = `${ICON_STORAGE_BASE_URL}/count-minus-gray400.svg`;

export const CAMERA_GRAY400_ICON_URL = `${ICON_STORAGE_BASE_URL}/camera-gray400.svg`;
export const INFO_GRAY200_ICON_URL = `${ICON_STORAGE_BASE_URL}/information-gray200.svg`;
export const CANCEL_CIRCLE_GRAY400_ICON_URL = `${ICON_STORAGE_BASE_URL}/cancel-circle-gray400.svg`;

export const CHART_ICON_URL = `${ICON_STORAGE_BASE_URL}/chart-icon.svg`;

export const ARROW_DOWN_WHITE_ICON_URL = `${ICON_STORAGE_BASE_URL}/arrow-down-white.svg`;

export const LANGUAGE_KOREAN_ICON_URL = `${ICON_STORAGE_BASE_URL}/language-korean.svg`;
export const LANGUAGE_ENGLISH_ICON_URL = `${ICON_STORAGE_BASE_URL}/language-english.svg`;

export const GLOBE_BLACK_ICON_URL = `${ICON_STORAGE_BASE_URL}/globe-black-icon.svg`;
