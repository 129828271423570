import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { brandAdminAxios } from './@core/brand';
import { DppDetailData } from '@customTypes/admin';

/**
 * [ADMIN] DPP 상세 조회 API
 */
const getDppDetail = async (no: string): Promise<DppDetailData> => {
  const res = await brandAdminAxios.get(`/dpp-detail?no=${no}`);

  return res?.data;
};

export const useDppDetail = () => {
  const { no: no } = useParams();

  return useQuery({
    queryKey: ['dppDetail', no],
    queryFn: () => (no ? getDppDetail(no) : Promise.reject('No no. provided')),
    enabled: !!no,
  });
};
