import { atom } from 'jotai';
import {
  CareLabelFormatSetting,
  CareLabelDppSetting,
  CareLabelTemplateSetting,
  CareLabelSettingData,
} from '@customTypes/admin/careLabel';

const initialCareLabelDppSetting: CareLabelDppSetting = {
  dpp: false,
};
export const careLabeldppSettingAtom = atom<CareLabelDppSetting>(
  initialCareLabelDppSetting
);

const initialCareLabelTemplateSetting: CareLabelTemplateSetting = {
  json: {},
};
export const careLabelTemplateSettingAtom = atom<CareLabelTemplateSetting>(
  initialCareLabelTemplateSetting
);

const initialCareLabelFormatSetting: CareLabelFormatSetting = {
  name: '',
  nation: 'kr',
  color_mode: 'blackandwhite',
  textile: '',
  option: 'single',
  width: 35,
  height: 160,
  seam_margin: 10,
  side_margin: 2,
};

export const careLabelformatSettingAtom = atom<CareLabelFormatSetting>(
  initialCareLabelFormatSetting
);

export const careLabelSettingDataAtom = atom<CareLabelSettingData>((get) => {
  const careLabelformatSetting = get(careLabelformatSettingAtom);
  const careLabeltemplateSetting = get(careLabelTemplateSettingAtom);
  const careLabeldppSetting = get(careLabeldppSettingAtom);

  return {
    ...careLabelformatSetting,
    ...careLabeltemplateSetting,
    ...careLabeldppSetting,
  };
});
