import { useState, useEffect } from 'react';
import { getCurrentSection } from '@lib/utils/scroll';

type SectionOffset = Record<string | number, number>;

/**
 * 스크롤 위치를 관찰하여 현재 활성화된 섹션을 추적하는 훅
 * @param sectionsOffsetTop - 각 섹션의 offsetTop 값을 담은 객체
 * @param containerRef - 스크롤 이벤트를 관찰할 컨테이너 요소의 ref (Optional)
 *
 * @returns 현재 활성화된 섹션 이름
 */
export const useSectionObserver = <T extends string>(
  sectionsOffsetTop: SectionOffset,
  containerRef?: HTMLDivElement | null
): T | null => {
  const [activeSection, setActiveSection] = useState<T | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = containerRef ? containerRef.scrollTop : window.scrollY;

      const newSection = getCurrentSection<T>(scrollTop, sectionsOffsetTop);
      setActiveSection(newSection);
    };

    // 초기 실행
    handleScroll();

    if (containerRef) {
      containerRef.addEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef) {
        containerRef.removeEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [sectionsOffsetTop, containerRef]);

  return activeSection;
};
