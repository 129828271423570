/**
 * 객체 받아서 쿼리 파라미터 생성해주는 함수
 *
 * @param params
 * @returns {string} 쿼리 파라미터
 */
export const getQueryParams = (params: Record<string, string>) => {
  return Object.entries(params)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};
