import { atom } from 'jotai';
import { ProductSizeSchemaType } from '@lib/apis/admin/@schema';
import { INIT_SIZE_FIELDS } from '@lib/constants/admin/careLabel/new';
import { FontItem, QrType } from '@customTypes/admin';

export const fontSearchKeywordAtom = atom<string>('');

export const fontListAtom = atom<FontItem[]>([]);

// 제품 사이즈 옵션 저장 (객체)
export const selectedOptionObjAtom =
  atom<ProductSizeSchemaType>(INIT_SIZE_FIELDS);

// QR 유형 선택
export const currQrTypeAtom = atom<QrType | ''>('');
currQrTypeAtom.onMount = (setAtom) => {
  return () => {
    setAtom('');
  };
};
