import { atom } from 'jotai';

// 페이지별 그리드 표시 상태를 관리하는 atom family
const gridVisibilityAtoms = new Map<string, ReturnType<typeof atom<boolean>>>();

export const getGridVisibilityAtom = (pageId: string) => {
  if (!gridVisibilityAtoms.has(pageId)) {
    gridVisibilityAtoms.set(pageId, atom(false));
  }
  return gridVisibilityAtoms.get(pageId)!;
};
