export const scrollFadeInUpVariants = {
  offscreen: {
    opacity: 0,
    transform: 'translateY(150px)',
  },
  onscreen: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: {
      duration: 1.2,
      ease: 'easeOut',
    },
  },
};
