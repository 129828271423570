import { Icon } from '@components/@shared/Icon';
import styled, { css } from 'styled-components';

interface ChipProps {
  label: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  $selected?: boolean;
}

export default function Chip({
  label,
  onClick,
  type = 'button',
  disabled = false,
  $selected = false,
  ...styles
}: ChipProps) {
  return (
    <StyledChip
      type={type}
      onClick={onClick}
      disabled={disabled}
      $selected={$selected}
      style={{ ...styles }}
    >
      <ChipContent>
        <span>{label}</span>
        {$selected && <Icon name="Close" width={12} height={12} />}
      </ChipContent>
    </StyledChip>
  );
}

interface StyledChipProps {
  $selected?: boolean;
}

const StyledChip = styled.button<StyledChipProps>`
  padding: 6px 12px;
  width: fit-content;
  font-size: 16px;
  font-weight: 300;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.gray500};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  word-break: keep-all;
  white-space: nowrap;

  ${(props) =>
    props.$selected &&
    css`
      color: ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.gray25};
      border: 1px solid ${(props) => props.theme.colors.gray400};
    `}

  /* Disabled */
  &:disabled {
    color: ${(props) => props.theme.colors.gray400};
    cursor: default;
  }

  /* Hovered */
  &:not(:disabled):hover {
    color: ${(props) => props.theme.colors.gray700};
  }

  /* Clicked */
  &:not(:disabled):active {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.gray25};
  }
`;

const ChipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
