import styled from 'styled-components';

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export default function ActionButton({
  label,
  onClick,
  disabled,
}: ActionButtonProps) {
  return (
    <Button type="button" onClick={onClick} disabled={!!disabled}>
      {label}
    </Button>
  );
}

const Button = styled.button`
  flex-shrink: 0;
  padding: 0 12px;
  height: 44px;
  font-size: 16px;
  border-radius: ${(props) => props.theme.borderRadiuses.lg};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};

  &:disabled {
    color: ${(props) => props.theme.colors.gray400};
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
