import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { accessTokenAtomWithPersistence } from '@store/login';
import { ImageListData } from '@customTypes/admin';
import { initCareImageSearchKeywords } from '@pages/admin/CareLabelImageListPage';
import { adminAxios } from './@core';

export const NUMBER_OF_CARE_IMAGE_LIST_ITEM = 10;

/**
 * [ADMIN] 케어라벨 이미지 리스트 조회 API
 */
const fetchCareLabelImageList = async (
  searchKeywords?: typeof initCareImageSearchKeywords,
  page?: number
): Promise<ImageListData> => {
  let queryParam = `?page=${page || 1}&items=${NUMBER_OF_CARE_IMAGE_LIST_ITEM}`;

  if (searchKeywords?.brand) {
    queryParam += `&brand=${searchKeywords.brand}`;
  }
  if (searchKeywords?.name) {
    queryParam += `&name=${searchKeywords.name}`;
  }

  const res = await adminAxios.get('/care-img' + queryParam);

  return res?.data;
};

export const useCareLabelImageList = (
  searchKeywords?: typeof initCareImageSearchKeywords,
  page?: number
) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  return useSuspenseQuery({
    queryKey: ['careLabelImageList', searchKeywords, page, accessToken],
    queryFn: () => fetchCareLabelImageList(searchKeywords, page),
  });
};

/**
 * [ADMIN] 케어라벨 이미지 생성 API
 */
const addCareLabelImage = async (formData: FormData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await adminAxios.post(`/care-img`, formData, config);

  return res?.data;
};

export const useCareLabelImageAdd = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelImageAdd'],
    mutationFn: (formData: FormData) => addCareLabelImage(formData),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['careLabelImageList'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] 케어라벨 이미지 삭제 API
 */
const removeCareLabelImage = async (imageList: string[]) => {
  const data = { names: imageList };
  const res = await adminAxios.delete(`/care-img`, { data });

  return res?.data;
};

export const useCareLabelImageRemove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelImageRemove'],
    mutationFn: (imageList: string[]) => removeCareLabelImage(imageList),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['careLabelImageList'] }),
      ]);
    },
  });
};
