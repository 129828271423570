import { CareLabelColorMode, GridItemLayout } from '@customTypes/admin';

export const getFilterByPrintColor = (
  colorMode: CareLabelColorMode,
  backgroundColor: string
) => {
  const color =
    colorMode === 'blackandwhite'
      ? backgroundColor === 'black'
        ? 'white'
        : 'black'
      : colorMode;

  switch (color) {
    // 금박 인쇄 (검은색 원단만 가능)
    case 'gold':
      return 'contrast(10) invert(1) sepia(1) saturate(980%) hue-rotate(330deg) brightness(1.1)';
    // 은박 인쇄 (검은색 원단만 가능)
    case 'silver':
      return 'contrast(10) invert(1)';
    // 회색 인쇄 (검은색 원단만 가능)
    case 'gray':
      return 'contrast(10) invert(1) brightness(0.6)';
    // 흰색 인쇄 (검은색 원단만 가능)
    case 'white':
      return 'contrast(10) invert(1) brightness(0.85)';
    // 검은색 인쇄 (흰색 원단만 가능)
    case 'black':
    default:
      return 'contrast(10)';
  }
};

export const calcLayerPositionInPx = (
  layout: GridItemLayout,
  unit: number,
  pxSideMargin: number,
  pxSeamMargin: number,
  zIndex: number
) => {
  const { x, y, w, h } = layout;

  return {
    position: 'absolute' as const,
    left: `${x * unit + pxSideMargin + 1}px`,
    top: `${y * unit + pxSeamMargin + 1}px`,
    width: `${w * unit - 2}px`,
    height: `${h * unit - 2}px`,
    zIndex,
  };
};
