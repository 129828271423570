import { atom } from 'jotai';
import { DppFieldSectionName } from '@customTypes/admin';

export const formContainerRefAtom = atom<HTMLDivElement | null>(null);
formContainerRefAtom.onMount = (setAtom) => {
  return () => {
    setAtom(null);
  };
};

export type SectionsOffsetTop = Record<DppFieldSectionName, number>;

const initSectionsOffsetTop: SectionsOffsetTop = {
  productInfo: 0,
  materialInfo: 0,
  supplierInfo: 0,
  regulationsInfo: 0,
  circularityInfo: 0,
  careInfo: 0,
  sustainabilityInfo: 0,
  identifierInfo: 0,
};

export const dppFormSectionsOffsetTopAtom = atom<SectionsOffsetTop>(
  initSectionsOffsetTop
);
dppFormSectionsOffsetTopAtom.onMount = (setAtom) => {
  return () => {
    setAtom(initSectionsOffsetTop);
  };
};

export const isDppFormRequiredOnlyAtom = atom<boolean>(false);
isDppFormRequiredOnlyAtom.onMount = (setAtom) => {
  return () => {
    setAtom(false);
  };
};
