import {
  DragEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { ItemCallback, Layout } from 'react-grid-layout';
import {
  calculateImageDimensions,
  updateMultipleSelection,
  updateSingleSelection,
} from '@lib/utils';
import {
  getGridVisibilityAtom,
  selectedToolAtom,
  viewportScaleAtom,
} from '@store/admin/careLabel';
import {
  Layer,
  LayerCategory,
  LayoutPosition,
  ToolType,
} from '@customTypes/admin/careLabel';
import { useLabelFormat } from './useLabelFormat';
import { useLayerController } from './useLayerController';

export function useGridInteraction(pageId: string) {
  const { unit, pxSideMargin, pxSeamMargin, actualWidth, actualHeight } =
    useLabelFormat();
  const {
    layers,
    selectedLayers,
    addLayer,
    toggleSelection,
    clearSelection,
    cloneLayers,
    updateLayouts,
    removeTempOriginalLayers,
    setTempOriginalLayers,
  } = useLayerController(pageId);

  const gridVisibilityAtom = useMemo(
    () => getGridVisibilityAtom(pageId),
    [pageId]
  );
  const [isGridVisible, setIsGridVisible] = useAtom(gridVisibilityAtom);
  const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);

  const [mouseDownPos, setMouseDownPos] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const viewportScale = useAtomValue(viewportScaleAtom);

  const [selectionBox, setSelectionBox] = useState<{
    start: { x: number; y: number };
    current: { x: number; y: number };
  } | null>(null);

  // 클릭 위치를 그리드 좌표로 변환
  const getGridPosition = useCallback(
    (e: MouseEvent) => {
      // 오버레이의 부모인 GridLayout을 탐색
      const gridLayout = (e.currentTarget as HTMLElement).parentElement;
      const rect = gridLayout?.getBoundingClientRect();
      if (!rect) {
        return { x: 0, y: 0 };
      }
      // containerPadding에 viewportScale 반영
      const scaledPaddingX = pxSideMargin * viewportScale;
      const scaledPaddingY = pxSeamMargin * viewportScale;

      // 클릭 위치를 스케일로 변환
      const offsetX = (e.clientX - rect.left - scaledPaddingX) / viewportScale;
      const offsetY = (e.clientY - rect.top - scaledPaddingY) / viewportScale;

      return {
        x: Math.floor(offsetX / unit),
        y: Math.floor(offsetY / unit),
      };
    },
    [unit, viewportScale, pxSideMargin, pxSeamMargin]
  );

  // 클릭된 레이어 찾기
  const findClickedLayer = useCallback(
    (position: LayoutPosition) => {
      return layers.find((layer) => {
        const { x: layerX, y: layerY, w, h } = layer.layout;

        return (
          position.x >= layerX &&
          position.x < layerX + w &&
          position.y >= layerY &&
          position.y < layerY + h
        );
      });
    },
    [layers]
  );

  // 바운딩 박스 계산하기
  const calculateBoundingBox = useCallback((items: Layout[]) => {
    const minX = Math.min(...items.map((item) => item.x));
    const minY = Math.min(...items.map((item) => item.y));
    const maxX = Math.max(...items.map((item) => item.x + item.w));
    const maxY = Math.max(...items.map((item) => item.y + item.h));

    return {
      i: 'bounding-box',
      x: minX,
      y: minY,
      w: maxX - minX,
      h: maxY - minY,
    };
  }, []);

  const boundingBoxLayout = useMemo(() => {
    if (selectedLayers.length < 2)
      return {
        i: 'bounding-box',
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      };

    const selectedLayouts = layers
      .filter((layer) => selectedLayers.includes(layer.id))
      .map((layer) => layer.layout);

    return calculateBoundingBox(selectedLayouts);
  }, [selectedLayers, layers]);

  // 선택 도구 동작 처리
  const handleSelectTool = useCallback(
    (clickedLayer: Layer | undefined, isShiftKey: boolean) => {
      setIsGridVisible(false);

      // 빈 공간 클릭 시 선택 해제
      if (!clickedLayer) {
        toggleSelection();
        return;
      }

      // Shift 키가 없는 경우: 다른 선택을 모두 해제하고 현재 레이어만 선택
      if (!isShiftKey) {
        toggleSelection(clickedLayer.id);
        return;
      }

      // Shift 키가 있는 경우: 기존 선택에 현재 레이어 추가
      toggleSelection(clickedLayer.id, true);
    },
    [toggleSelection, clearSelection]
  );

  // 도형 도구 동작 처리
  const handleShapeTool = useCallback(
    (tool: ToolType, position: LayoutPosition) => {
      /**
       * @todo 이미지, 아이콘, QR도 추후 추가 예정
       */
      if (tool === 'square' || tool === 'line' || tool === 'text') {
        const newLayerId = addLayer({
          category: tool,
          layout: position,
        });
        toggleSelection(newLayerId);

        // 새로운 레이어 생성 후 'select' 모드로 전환
        setSelectedTool('select');
      }
    },
    [addLayer, toggleSelection, setSelectedTool]
  );

  // 클릭 이벤트 핸들러
  const handleGridClick = useCallback(
    (e: MouseEvent) => {
      const position = getGridPosition(e);
      const clickedLayer = findClickedLayer(position);

      switch (selectedTool) {
        /**
         * @todo 이미지, 아이콘, QR도 추후 추가 예정
         */
        case 'square':
        case 'line':
        case 'text':
          handleShapeTool(selectedTool, position);
          break;

        case 'select':
        default:
          handleSelectTool(clickedLayer, e.shiftKey);
          break;
      }
    },
    [
      selectedTool,
      getGridPosition,
      findClickedLayer,
      handleSelectTool,
      handleShapeTool,
    ]
  );

  // image, icon
  const handleDrop = useCallback(
    async (layout: Layout[], item: Layout, e: Event) => {
      // unknown을 거쳐서 DragEvent로 타입 단언
      const dragEvent = e as unknown as DragEvent<HTMLElement>;

      const category = dragEvent.dataTransfer?.getData('text/plain') || 'image';
      const imageUrl = dragEvent.dataTransfer?.getData('image/url');
      if (!imageUrl) return;

      const { x, y } = item;
      const { w, h } = await calculateImageDimensions(imageUrl);

      const newLayerId = addLayer({
        category: category as LayerCategory,
        layout: { x, y, w, h },
        src: imageUrl,
      });

      // toggleSelection(newLayerId);
    },
    [addLayer, toggleSelection]
  );

  const handleDragStart: ItemCallback = useCallback(
    (layout, oldItem, newItem, placeholder, e, element) => {
      e.stopPropagation();

      // 라벨 외부에서 드래그앤드롭으로 가져오는 경우
      // 그리드 라인 보이지 않도록 처리
      const isInternalItem =
        oldItem.i === 'bounding-box' ||
        layers.some((layer) => layer.id === oldItem.i);

      // 실제 드래그가 시작될 때만 그리드를 보이게 함
      const isDragging = e.type === 'dragstart' || e.buttons === 1; // 마우스 왼쪽 버튼이 눌린 상태

      if (isDragging && isInternalItem) {
        setIsGridVisible(true);

        if ((e as unknown as KeyboardEvent)?.altKey) {
          // 1. 현재 선택된 레이어들만 필터링
          const selected = layers.filter((layer) =>
            selectedLayers.includes(layer.id)
          );

          // 2. 선택된 레이어들을 원본 레이어로 설정 (for 임시 렌더링)
          setTempOriginalLayers(selected);

          // 3. 레이어 복제
          cloneLayers();
        }
      }

      // 드래그하는 아이템이 선택되지 않은 상태일 때만 toggleSelection 호출
      // 아이콘, 이미지는 추가적으로 D&D할 수 있도록 선택하지 않음
      if (
        oldItem.i !== 'bounding-box' &&
        !selectedLayers.includes(oldItem.i) &&
        selectedTool !== 'icon' &&
        selectedTool !== 'image'
      ) {
        toggleSelection(oldItem.i, e.shiftKey);
        return;
      }

      if (!isInternalItem) {
        toggleSelection();
      }
    },
    [
      layers,
      selectedLayers,
      selectedTool,
      setIsGridVisible,
      toggleSelection,
      cloneLayers,
      setTempOriginalLayers,
    ]
  );

  const handleDrag: ItemCallback = useCallback(
    (layout, oldItem, newItem, placeholder, e) => {
      // 바운딩 박스가 아닐 때는 그대로 반환
      if (oldItem.i !== 'bounding-box') return;

      // 바운딩 박스 드래그 시 선택된 레이어들을 동시에 이동
      const dx = newItem.x - oldItem.x;
      const dy = newItem.y - oldItem.y;

      // 선택된 아이템들의 이동 가능 여부 확인
      const canMove = selectedLayers.every((layerId) => {
        const layer = layout.find((l) => l.i === layerId);
        if (!layer) return false;

        const newX = layer.x + dx;
        const newY = layer.y + dy;
        return newX >= 0 && newY >= 0;
      });

      if (!canMove) return;

      // 바운딩 박스와 선택된 레이어들을 동시에 이동
      const updatedLayout = layout.map((item) => {
        if (item.i === 'bounding-box') return newItem;
        if (!selectedLayers.includes(item.i)) return item;

        return {
          ...item,
          x: item.x + dx,
          y: item.y + dy,
        };
      });

      updateLayouts(updatedLayout);
      return;
    },
    [selectedLayers, updateLayouts, unit]
  );

  const handleDragStop: ItemCallback = useCallback(
    (layout, oldItem, newItem, placeholder, e) => {
      e.preventDefault();
      e.stopPropagation();

      if (selectedLayers.length === 0) {
        toggleSelection(oldItem.i);
      }

      setIsGridVisible(false);

      removeTempOriginalLayers();

      // 바운딩 박스 드래그의 경우 이미 handleDrag에서 처리됨
      if (oldItem.i === 'bounding-box') return;

      // 일반 아이템 드래그의 경우 여기서 처리
      updateLayouts(layout);

      // 도중 Alt 키를 뗐을 경우 복제된 레이어들 삭제
      if ((e as unknown as KeyboardEvent)?.altKey) {
        // 도중 Alt 키를 뗐을 경우 복제된 레이어들 삭제
      }
    },
    [
      selectedLayers,
      updateLayouts,
      setIsGridVisible,
      cloneLayers,
      removeTempOriginalLayers,
    ]
  );

  const handleResizeStart: ItemCallback = useCallback(() => {
    if (selectedTool !== 'select') {
      setSelectedTool('select');
    }

    setIsGridVisible(true);
  }, [setIsGridVisible]);

  const handleResize: ItemCallback = useCallback(
    (layout, oldItem, newItem, placeholder, e) => {
      if (oldItem.i !== 'bounding-box') return;

      // bounding-box 레이어 체크 제거
      // Shift 키가 눌린 경우 비율 유지
      const isRatioLocked =
        e.shiftKey ||
        selectedLayers.some((layerId) => {
          const layer = layers.find((l) => l.id === layerId);
          return layer?.category === 'icon' || layer?.category === 'qr';
        });

      const originalRatio = oldItem.w / oldItem.h;

      let finalWidth = newItem.w;
      let finalHeight = newItem.h;

      // 레이아웃 최대 크기 제한
      const maxWidth = layout[0].w - newItem.x;
      const maxHeight = layout[0].h - newItem.y;

      if (isRatioLocked) {
        const widthScale = Math.min(
          newItem.w / oldItem.w,
          maxWidth / oldItem.w
        );
        const heightScale = Math.min(
          newItem.h / oldItem.h,
          maxHeight / oldItem.h
        );
        const dominantScale =
          Math.abs(widthScale - 1) > Math.abs(heightScale - 1)
            ? widthScale
            : heightScale;

        // 비율을 유지하면서 최대 크기 제한 적용
        finalWidth = Math.min(Math.round(oldItem.w * dominantScale), maxWidth);
        finalHeight = Math.min(
          Math.round(finalWidth / originalRatio),
          maxHeight
        );
      } else {
        finalWidth = Math.min(newItem.w, maxWidth);
        finalHeight = Math.min(newItem.h, maxHeight);
      }

      const scaleX = finalWidth / oldItem.w;
      const scaleY = isRatioLocked ? scaleX : finalHeight / oldItem.h;

      // 모든 선택된 아이템의 새 위치와 크기가 유효한지 확인
      const canResize = selectedLayers.every((layerId) => {
        const item = layout.find((l) => l.i === layerId);
        if (!item) return false;

        const relativeX = item.x - oldItem.x;
        const relativeY = item.y - oldItem.y;

        const newX = newItem.x + relativeX * scaleX;
        const newY = newItem.y + relativeY * scaleY;
        const newW = Math.max(1, Math.round(item.w * scaleX));
        const newH = Math.max(1, Math.round(item.h * scaleY));

        return (
          newX >= 0 &&
          newY >= 0 &&
          newX + newW <= layout[0].w &&
          newY + newH <= layout[0].h
        );
      });

      if (!canResize) return;

      const updatedLayout = layout.map((item) => {
        if (!selectedLayers.includes(item.i)) return item;

        const relativeX = item.x - oldItem.x;
        const relativeY = item.y - oldItem.y;

        return {
          ...item,
          x: newItem.x + relativeX * scaleX,
          y: newItem.y + relativeY * scaleY,
          w: Math.max(1, Math.round(item.w * scaleX)),
          h: Math.max(1, Math.round(item.h * scaleY)),
        };
      });

      updateLayouts(updatedLayout);
    },
    [selectedLayers, layers, updateLayouts]
  );

  const handleResizeStop: ItemCallback = useCallback(
    (layout: Layout[], oldItem: Layout, newItem: Layout, placeholder, e) => {
      setIsGridVisible(false);

      if (oldItem.i === 'bounding-box') return;

      const updatedLayout = layout.map((item) => {
        const layer = layers.find((l) => l.id === item.i);

        // 직선인 경우 높이 유지
        if (layer?.category === 'line') {
          return { ...item, h: layer.layout.h };
        }

        // Shift 키가 눌린 경우 비율 유지
        if (e.shiftKey && item.i === oldItem.i) {
          const originalRatio = oldItem.w / oldItem.h;
          const widthScale = newItem.w / oldItem.w;
          const heightScale = newItem.h / oldItem.h;
          const dominantScale =
            Math.abs(widthScale - 1) > Math.abs(heightScale - 1)
              ? widthScale
              : heightScale;

          return {
            ...item,
            w: Math.round(oldItem.w * dominantScale),
            h: Math.round((oldItem.w * dominantScale) / originalRatio),
          };
        }

        return item;
      });

      updateLayouts(updatedLayout);
    },
    [layers, updateLayouts, setIsGridVisible]
  );

  const targetLayout = useMemo(
    () =>
      selectedLayers.length === 1
        ? layers.find((layer) => layer.id === selectedLayers[0])?.layout
        : boundingBoxLayout,
    [selectedLayers, layers, boundingBoxLayout]
  );

  const handleSizeChange = useCallback(
    (
      newSize: number,
      isWidth: boolean,
      isRatioLocked: boolean = false
    ): void => {
      if (!targetLayout) return;

      const scale = newSize / (isWidth ? targetLayout.w : targetLayout.h);

      const maxMainSize = isWidth
        ? actualWidth - targetLayout.x
        : actualHeight - targetLayout.y;

      const maxCrossSize = isWidth
        ? actualHeight - targetLayout.y
        : actualWidth - targetLayout.x;

      const params = {
        targetLayout,
        newSize,
        maxMainSize,
        maxCrossSize,
        scale,
        isRatioLocked,
        isWidth,
      };

      const updatedLayout = layers.map((layer) => {
        if (!selectedLayers.includes(layer.id)) return layer.layout;

        // 다중 선택
        if (selectedLayers.length > 1) {
          return updateMultipleSelection({ layer, ...params });
        }
        // 단일 선택
        return updateSingleSelection({ layer, ...params });
      });

      updateLayouts(updatedLayout);
    },
    [
      layers,
      selectedLayers,
      targetLayout,
      updateLayouts,
      actualWidth,
      actualHeight,
    ]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!selectionBox) return;

      const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      const x = (e.clientX - rect.left) / viewportScale;
      const y = (e.clientY - rect.top) / viewportScale;
      setSelectionBox((prev) => ({ ...prev!, current: { x, y } }));

      const selectionBounds = {
        left: Math.min(selectionBox.start.x, x),
        right: Math.max(selectionBox.start.x, x),
        top: Math.min(selectionBox.start.y, y),
        bottom: Math.max(selectionBox.start.y, y),
      };

      const intersectingLayers = layers
        .filter((layer) => {
          const layerBounds = {
            left: layer.layout.x * unit + pxSideMargin,
            right: (layer.layout.x + layer.layout.w) * unit + pxSideMargin,
            top: layer.layout.y * unit + pxSeamMargin,
            bottom: (layer.layout.y + layer.layout.h) * unit + pxSeamMargin,
          };

          return !(
            selectionBounds.right < layerBounds.left ||
            selectionBounds.left > layerBounds.right ||
            selectionBounds.bottom < layerBounds.top ||
            selectionBounds.top > layerBounds.bottom
          );
        })
        .map((layer) => layer.id);

      if (e.shiftKey && selectedLayers.length > 0) {
        // Shift 키가 눌려있고 기존 선택이 있으면, 기존 선택을 유지하면서 새로운 레이어 추가
        const newSelectedLayers = [
          ...new Set([...selectedLayers, ...intersectingLayers]),
        ];
        clearSelection();
        newSelectedLayers.forEach((id) => toggleSelection(id, true));
      } else {
        // 그렇지 않으면 새로운 선택으로 대체
        clearSelection();
        intersectingLayers.forEach((id) => toggleSelection(id, true));
      }
    },
    [
      selectionBox,
      layers,
      unit,
      viewportScale,
      pxSideMargin,
      pxSeamMargin,
      clearSelection,
      toggleSelection,
      selectedLayers,
    ]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (!(e.target as HTMLElement).classList.contains('overlay')) return;

      // e.preventDefault();
      // e.stopPropagation();

      const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      const x = (e.clientX - rect.left) / viewportScale;
      const y = (e.clientY - rect.top) / viewportScale;

      setMouseDownPos({ x, y });
      setSelectionBox({ start: { x, y }, current: { x, y } });
    },
    [viewportScale, selectedTool]
  );

  const handleMouseUp = useCallback(
    (e: React.MouseEvent) => {
      if (!mouseDownPos) return;

      const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
      const currentX = (e.clientX - rect.left) / viewportScale;
      const currentY = (e.clientY - rect.top) / viewportScale;

      // 마우스 다운 위치와 현재 위치의 차이가 미미하면 클릭으로 간주
      const isClick =
        Math.abs(currentX - mouseDownPos.x) < 5 &&
        Math.abs(currentY - mouseDownPos.y) < 5;

      setSelectionBox(null);
      setMouseDownPos(null);

      if (isClick) {
        handleGridClick(e);
      }
    },
    [mouseDownPos, viewportScale, handleGridClick]
  );

  return {
    boundingBoxLayout,
    targetLayout,
    isGridVisible,
    handleGridClick,
    handleDrop,
    handleDragStart,
    handleDrag,
    handleDragStop,
    handleResizeStart,
    handleResize,
    handleResizeStop,
    handleSizeChange,
    //
    selectionBox,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
}
