import { Tool } from '@customTypes/admin';

export const TOOL_BAR_CONTROL_ITEMS: Tool[] = [
  {
    type: 'select',
    label: '선택',
    iconName: 'DesignToolSelect',
  },
];

export const TOOL_BAR_CREATE_ITEMS: Tool[] = [
  {
    type: 'text',
    label: '텍스트',
    iconName: 'DesignToolText',
  },
  {
    type: 'square',
    label: '사각형',
    iconName: 'DesignToolSquare',
  },
  {
    type: 'line',
    label: '직선',
    iconName: 'DesignToolLine',
  },
];

export const TOOL_BAR_IMPORT_ITEMS: Tool[] = [
  {
    type: 'image',
    label: '이미지',
    iconName: 'DesignToolImage',
  },
  {
    type: 'icon',
    label: '아이콘',
    iconName: 'DesignToolIcon',
  },
  {
    type: 'size',
    label: '사이즈',
    iconName: 'DesignToolSize',
  },
  {
    type: 'qr',
    label: 'QR',
    iconName: 'DesignToolQr',
  },
];

export const TOOL_BAR_DESCRIPTION_ITEMS: Tool[] = [
  {
    type: 'guide',
    label: '가이드',
    iconName: 'DesignToolGuide',
  },
  {
    type: 'shortcut',
    label: '단축키',
    iconName: 'DesignToolShortcut',
  },
];

export const TOOL_BAR_ITEMS: Tool[] = [
  ...TOOL_BAR_CONTROL_ITEMS,
  ...TOOL_BAR_CREATE_ITEMS,
  ...TOOL_BAR_IMPORT_ITEMS,
  ...TOOL_BAR_DESCRIPTION_ITEMS,
];
