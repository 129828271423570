import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { useAtom } from 'jotai';
import { accessTokenAtomWithPersistence } from '@store/login';
import {
  categoryFilterAtom,
  numberOfItemsAtom,
  formattedDateRangeFilterAtom,
} from '@store/admin/dppInfoList/common';
import { AdminDppLevelFiveData, DppInfoListData } from '@customTypes/admin';
import { initDppInfoSearchKeywords } from '@pages/admin/DppInfoListPage';
import { adminAxios } from './@core';
import { useState } from 'react';
import { ProductErrorInfoData } from '@lib/utils';

/**
 * [ADMIN] DPP 정보 리스트 조회 API
 */
const fetchDppInfoList = async (
  searchKeywords?: typeof initDppInfoSearchKeywords,
  page?: number,
  numberOfItems?: string,
  category?: string,
  dateRange?: string[]
): Promise<DppInfoListData> => {
  let queryParam = `?page=${page || 1}&items=${numberOfItems}&cate=${category}&date_start=${dateRange?.at(0)}&date_end=${dateRange?.at(1)}`;

  if (searchKeywords?.brand) {
    queryParam += `&brand=${searchKeywords.brand}`;
  }
  if (searchKeywords?.name) {
    queryParam += `&name=${searchKeywords.name}`;
  }
  if (searchKeywords?.id) {
    queryParam += `&id=${searchKeywords.id}`;
  }

  const res = await adminAxios.get('/products' + queryParam);

  return res?.data;
};

export const useDppInfoList = (
  searchKeywords?: typeof initDppInfoSearchKeywords,
  page?: number
) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);
  const [numberOfItems] = useAtom(numberOfItemsAtom);
  const [category] = useAtom(categoryFilterAtom);
  const [dateRange] = useAtom(formattedDateRangeFilterAtom);

  return useSuspenseQuery({
    queryKey: [
      'dppInfoList',
      searchKeywords,
      page,
      accessToken,
      numberOfItems,
      category,
      dateRange.at(0),
      dateRange.at(1),
    ],
    queryFn: () =>
      fetchDppInfoList(
        searchKeywords,
        page,
        numberOfItems,
        category,
        dateRange
      ),
  });
};

/**
 * [ADMIN] DPP 정보 상세 조회 API
 */
const fetchDppInfoDetail = async (
  productId: string | undefined
): Promise<AdminDppLevelFiveData> => {
  const res = await adminAxios.get(`/product/${productId}`);

  return res?.data;
};

export const useDppInfoDetail = (productId: string | undefined) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  return useQuery({
    queryKey: ['dppInfoDetail', productId, accessToken],
    queryFn: () => fetchDppInfoDetail(productId),
    enabled: !!productId,
  });
};

export interface DppInfoExcelUploadResponse {
  data?: ProductErrorInfoData[];
  msg?: string;
}

/**
 * [ADMIN] DPP 정보 추가 API - 엑셀
 */
const addDppInfoWithExcel = async (
  formData: FormData,
  setProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      setProgress(progressEvent);
    },
  };

  const res = (await adminAxios.post(
    '/product-excel',
    formData,
    config
  )) as DppInfoExcelUploadResponse;

  return res;
};

export const useDppInfoExcelAdd = () => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);

  const handleProgressSet = (progressEvent: AxiosProgressEvent) => {
    // loaded는 현재 업로드된 바이트 수, total은 전체 바이트 수를 나타냄
    const { loaded, total } = progressEvent;
    if (!total) return;

    // 업로드 진행률
    const percentage = Math.floor((loaded / total) * 100);
    setProgress(percentage);
  };

  const mutation = useMutation({
    mutationKey: ['dppInfoAddExcel'],
    mutationFn: (formData: FormData) =>
      addDppInfoWithExcel(formData, handleProgressSet),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppInfoList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppInfoDetail'] }),
      ]);
    },
  });

  return { ...mutation, progress };
};

/**
 * [ADMIN] DPP 정보 추가 API - 직접 추가
 */
const addDppInfo = async (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await adminAxios.post('/product', data, config);

  return res?.data;
};

export const useDppInfoAdd = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['dppInfoAdd'],
    mutationFn: (data: any) => addDppInfo(data),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppInfoList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppInfoDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] DPP 정보 수정 API
 */
const updateDppInfo = async (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await adminAxios.patch('/product', data, config);

  return res?.data;
};

export const useDppInfoUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['dppInfoUpdate'],
    mutationFn: (data: any) => updateDppInfo(data),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppInfoList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppInfoDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] DPP 정보 삭제 API
 */
const removeDppInfo = async (productList: string[]) => {
  const data = { products: productList };
  const res = await adminAxios.delete(`/product`, { data });

  console.log(res);
  return res?.data;
};

export const useDppInfoRemove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['dppInfoRemove'],
    mutationFn: (productList: string[]) => removeDppInfo(productList),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppInfoList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppInfoDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] DPP 정보 QR 링크 목록 다운로드 요청 API
 */
const downloadQrLinksExcel = async (productList: string[]) => {
  const data = { product_id: productList };

  const config: AxiosRequestConfig = {
    responseType: 'blob',
  };

  const res = await adminAxios.post(`/download-product-excel`, data, config);
  console.log(res);

  return res;
};

export const useQrLinksExcelDownload = () => {
  return useMutation({
    mutationKey: ['dppInfoExcelDownload'],
    mutationFn: (productList: string[]) => downloadQrLinksExcel(productList),
  });
};
