import { atom } from 'jotai';
import { GuideNationType } from '@customTypes/admin/careLabel/guide';

export const currNationAtom = atom<GuideNationType>('kr');
export const activeTabIndexAtom = atom<number>(0);

export type GuideSectionsOffsetTop = Record<number, number>;

const initSectionsOffsetTop: GuideSectionsOffsetTop = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
};

export const guideSectionsOffsetTopAtom = atom<GuideSectionsOffsetTop>(
  initSectionsOffsetTop
);
