import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { accessTokenAtomWithPersistence } from '@store/login';
import { BrandDetailData, BrandListData } from '@customTypes/admin/brand';
import { initBrandSearchKeywords } from '@pages/admin/BrandListPage';
import { adminAxios } from './@core';

export const NUMBER_OF_BRAND_LIST_ITEM = 10;

/**
 * [ADMIN] 브랜드 리스트 조회 API
 */
const fetchBrandList = async (
  searchKeywords?: typeof initBrandSearchKeywords,
  page?: number
): Promise<BrandListData> => {
  let queryParam = `?page=${page || 1}&items=${NUMBER_OF_BRAND_LIST_ITEM}`;

  if (searchKeywords?.name) {
    queryParam += `&name=${searchKeywords.name}`;
  }

  const res = await adminAxios.get('/brand' + queryParam);

  return res?.data;
};

export const useBrandList = (
  searchKeywords?: typeof initBrandSearchKeywords,
  page?: number
) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  return useSuspenseQuery({
    queryKey: ['brandList', searchKeywords, page, accessToken],
    queryFn: () => fetchBrandList(searchKeywords, page),
  });
};

/**
 * [ADMIN] 브랜드 상세 조회 API
 */
const fetchBrandDetail = async (
  brandName: string | undefined
): Promise<BrandDetailData> => {
  const res = await adminAxios.get(`/brand-detail/${brandName}`);

  return res?.data;
};

export const useBrandDetail = (brandName: string | undefined) => {
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);

  return useQuery({
    queryKey: ['brandDetail', brandName, accessToken],
    queryFn: () => fetchBrandDetail(brandName),
    enabled: !!brandName,
  });
};

/**
 * [ADMIN] 브랜드 생성 API
 */
const addBrand = async (formData: FormData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await adminAxios.post(`/brand`, formData, config);

  return res?.data;
};

export const useBrandAdd = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['brandAdd'],
    mutationFn: (formData: FormData) => addBrand(formData),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['brandList'] }),
        queryClient.invalidateQueries({ queryKey: ['brandDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] 브랜드 수정 API
 */
const updateBrand = async (formData: FormData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await adminAxios.patch(`/brand`, formData, config);

  return res?.data;
};

export const useBrandUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['brandAdd'],
    mutationFn: (formData: FormData) => updateBrand(formData),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['brandList'] }),
        // queryClient.invalidateQueries({ queryKey: ['brandDetail'] }),
        queryClient.invalidateQueries({ queryKey: ['productImageList'] }),
        queryClient.invalidateQueries({ queryKey: ['careLabelImageList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppInfoList'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] 브랜드 삭제 API
 */
const removeBrand = async (brandList: string[]) => {
  const data = { brands: brandList };
  const res = await adminAxios.delete(`/brand`, {
    data,
  });

  return res?.data;
};

export const useBrandRemove = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['brandRemove'],
    mutationFn: (brandList: string[]) => removeBrand(brandList),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['brandList'] }),
        queryClient.invalidateQueries({ queryKey: ['brandDetail'] }),
      ]);
    },
  });
};
