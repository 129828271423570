import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomAxiosResponseType } from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';

/**
 * [ADMIN] DPP 추가 API
 */
const updateDpp = async (data: any) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = (await brandAdminAxios.patch(
    '/dpp',
    data,
    config
  )) as CustomAxiosResponseType;

  return res?.msg;
};

export const useDppUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['dppUpdate'],
    mutationFn: (data: any) => updateDpp(data),
    onSuccess: async () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['dppList'] }),
        queryClient.invalidateQueries({ queryKey: ['dppDetail'] }),
      ]);
    },
  });
};
