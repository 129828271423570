import { atom } from 'jotai';
import { getQueryParams } from '@lib/utils';
import { CareLabelDppListSearchKeywords } from '@customTypes/admin';

export const isDppLinkedAtom = atom<boolean>(false);
export const selectedDppNameAtom = atom<string>('');

export const careLabelDppPageItemsAtom = atom<number>(10);
export const careLabelDppPageAtom = atom<number>(1);
careLabelDppPageAtom.onMount = (setAtom) => {
  setAtom(1);
};
export const careLabelDppOffsetAtom = atom<number>(1);
careLabelDppOffsetAtom.onMount = (setAtom) => {
  setAtom(1);
};

export const initDppSearchKeywords: CareLabelDppListSearchKeywords = {
  name: '',
  sku: '',
};

// DPP 리스트 조회 검색어
export const careLabelDppSearchKeywordsAtom =
  atom<CareLabelDppListSearchKeywords>(initDppSearchKeywords);
careLabelDppSearchKeywordsAtom.onMount = (setAtom) => {
  setAtom(initDppSearchKeywords);
};

// DPP 리스트 조회 쿼리 파라미터 (페이지, 페이지당 개수, 검색어)
export const careLabelDppQueryParamsAtom = atom<string>((get) => {
  const page = get(careLabelDppPageAtom);
  const items = get(careLabelDppPageItemsAtom);
  const searchKeywords = get(careLabelDppSearchKeywordsAtom);

  let params = `?page=${page}&items=${items}`;
  params += `&${getQueryParams(searchKeywords)}`;

  return params;
});
