/**
 * 스크롤을 방지하고, 현재 위치를 반환하는 함수
 * @returns {number} 현재 스크롤 위치
 */
export const preventScroll = (): number => {
  const currentScrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
  document.body.style.overflowY = 'scroll';
  return currentScrollY;
};

/**
 * 스크롤을 허용하고, 스크롤 방지 함수에서 반환된 위치로 이동시키는 함수
 * @param prevScrollY 스크롤 방지 함수에서 반환된 스크롤 위치
 */
export const allowScroll = (prevScrollY: number) => {
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.top = '';
  document.body.style.overflowY = '';
  window.scrollTo(0, prevScrollY);
};

type SectionOffset = Record<string, number>;

/**
 * 현재 스크롤 위치에 해당하는 섹션을 찾는 함수
 *
 * @param scrollY - 현재 스크롤 위치
 * @param sectionsOffsetTop - 각 섹션의 offsetTop 값을 담은 객체
 *
 * @returns 현재 스크롤 위치에 해당하는 섹션 이름
 */
export const getCurrentSection = <T extends string>(
  scrollY: number,
  sectionsOffsetTop: SectionOffset
): T | null => {
  const sortedSections = Object.entries(sectionsOffsetTop).sort(
    ([, offsetA], [, offsetB]) => offsetA - offsetB
  );

  for (let i = sortedSections.length - 1; i >= 0; i--) {
    const [sectionName, offsetTop] = sortedSections[i];

    if (scrollY >= offsetTop) {
      return sectionName as T;
    }
  }

  return sortedSections[0][0] as T;
};
