import { useCallback } from 'react';
import { Path, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';

interface UseFormFieldProps<T extends Record<string, any>> {
  setValue: UseFormSetValue<T>;
  clearErrors: UseFormClearErrors<T>;
}

export const useFormField = <T extends Record<string, any>>({
  setValue,
  clearErrors,
}: UseFormFieldProps<T>) => {
  const handleValueSet = useCallback(
    (inputName: Path<T>, value: any) => {
      setValue(inputName, value, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const handleValueReset = useCallback(
    (inputName: Path<T>) => {
      setValue(inputName, undefined as any, {
        shouldValidate: true,
        shouldTouch: true,
      });
      clearErrors(inputName);
    },
    [setValue, clearErrors]
  );

  return {
    handleValueSet,
    handleValueReset,
  };
};
