import { forwardRef, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Icon } from '@components/@shared';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  inputName?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onReset?: () => void;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { inputName, placeholder, value = '', disabled = false, onReset, ...props },
    ref
  ) => {
    return (
      <FieldTextareaContainer>
        <FieldTextarea
          id={inputName}
          name={inputName}
          placeholder={placeholder}
          ref={ref}
          disabled={disabled}
          {...props}
        />
        {onReset && !disabled && value && (
          <ResetButton type="button" onClick={onReset} tabIndex={-1}>
            <Icon name="Close" fill="gray400" />
          </ResetButton>
        )}
      </FieldTextareaContainer>
    );
  }
);
// DevTools에서 컴포넌트 이름 표시를 위해 설정
Textarea.displayName = 'Textarea';
export default Textarea;

const FieldTextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: stretch;
`;

const FieldTextarea = styled.textarea`
  padding: 10px;
  width: 100%;
  height: 110px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }

  /* Disabled */
  &:disabled {
    border-color: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray300};
    background-color: ${(props) => props.theme.colors.gray50};
  }

  &:disabled::placeholder {
    color: ${(props) => props.theme.colors.gray300};
  }
`;

const ResetButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
`;
