export const convertDateFormat = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);

  return `${year}.${month}.${day}`;
};

export const convertTimestampToDateTimeFormat = (timestamp: string) => {
  /**
   * ISO 8601 형식의 타임스탬프를 원하는 형식의 날짜 문자열로 변환
   *
   * @param {string} timestamp - ISO 8601 형식의 타임스탬프
   * @returns {string} - 원하는 형식의 날짜 문자열
   */

  // ISO 8601 형식의 타임스탬프로 새로운 날짜 객체 만들기
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

export const convertTimestampToKoreanFormat = (timestamp: string) => {
  /**
   * ISO 8601 형식의 타임스탬프를 원하는 형식의 날짜 문자열로 변환
   *
   * @param {string} timestamp - ISO 8601 형식의 타임스탬프
   * @returns {string} - 원하는 형식의 날짜 문자열
   */

  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일 ${hours}시`;
};

export const toKoreanISO = (date: Date): string => {
  const offset = new Date().getTimezoneOffset() * 60000;
  const koreanDate = new Date(date.getTime() - offset);

  return koreanDate.toISOString();
};

export const convertToDateRangeLabel = (dates: string[]) => {
  if (!dates[0] && !dates[1]) {
    return '';
  }

  return dates.reduce((acc, curr) => {
    if (acc === '') {
      return acc + convertDateFormat(curr);
    }

    return acc + ' - ' + convertDateFormat(curr);
  }, '');
};

export const convertDateToNavLabelFormat = (date: Date) => {
  const koreanDate = toKoreanISO(date);
  const year = koreanDate.slice(0, 4);
  const month = koreanDate.slice(5, 7);

  return `${year}.${month}`;
};

export const calcDDay = (createdAt: string, days: number) => {
  const now = new Date().getTime();
  const start = new Date(createdAt).getTime();
  const passedTime = now - start;
  // 밀리초를 일로 변환 후 반올림
  const passedDay = Math.round(passedTime / (24 * 60 * 60 * 1000));
  const res = days - passedDay;

  return 'D' + (res < 0 ? '+' : '-') + Math.abs(res);
};
