import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { useImageUsageCheck } from '@lib/apis/admin';
import { currentPageIdAtom, syncPagesLayersAtom } from '@store/admin/careLabel';
import { useLayerController } from './useLayerController';
import { useLabelFormat } from './useLabelFormat';
import { DEFAULT_LAYER_CONFIGS } from '@lib/constants/admin/careLabel';

interface DeleteWarningState {
  // 이미지가 사용중인 곳
  stage: 'local' | 'server' | null;
  // 삭제 경고 메시지
  message: string;
}

const initDeleteWarningState: DeleteWarningState = {
  stage: null,
  message: '',
};

export const useImageController = (imageUrl: string) => {
  const [pages] = useAtom(syncPagesLayersAtom);
  const [deleteWarningState, setDeleteWarningState] =
    useState<DeleteWarningState>(initDeleteWarningState);
  // 서버에서 이미지 사용 여부 확인하는 API
  const { refetch: refetchServerUsage } = useImageUsageCheck(imageUrl);

  const [currPageId] = useAtom(currentPageIdAtom);
  const {
    selectedLayers,
    getLayerById,
    updateLayerImageSrc,
    addLayer,
    toggleSelection,
  } = useLayerController(currPageId);
  const { center } = useLabelFormat();

  const addImageLayer = useCallback(
    (category: 'image' | 'icon') => {
      const w = 10;
      const h = 10;

      const defaultConfig = DEFAULT_LAYER_CONFIGS[category];
      const layout = {
        ...defaultConfig.layout,
        x: center.x - w / 2,
        y: center.y - h / 2,
      };

      const newLayerId = addLayer({
        category,
        src: imageUrl,
        layout,
      });

      toggleSelection(newLayerId);
    },
    [addLayer, imageUrl]
  );

  const changeImageSrc = useCallback(
    (newImageUrl: string) => {
      const layerId = selectedLayers.length === 1 && selectedLayers[0];
      if (!layerId) return;

      const layer = getLayerById(layerId);
      if (!layer) return;

      updateLayerImageSrc(layerId, newImageUrl);
    },
    [selectedLayers, getLayerById, updateLayerImageSrc]
  );

  // 작업 중인 케어라벨 레이어에서 이미지 사용 여부 확인
  const checkLocalImageUsage = useCallback(
    (imageUrl: string) => {
      if (!pages) return false;

      return pages.some((page) =>
        page.layers.some(
          (layer) => layer.category === 'image' && layer.src === imageUrl
        )
      );
    },
    [pages]
  );

  /**
   * 로컬에서 이미지 사용 여부 확인
   * @returns 이미지 사용 여부
   */
  const checkLocalUsage = async () => {
    const isImageUsed = checkLocalImageUsage(imageUrl);
    if (!isImageUsed) return false;

    setDeleteWarningState({
      stage: 'local',
      message:
        '삭제하려는 이미지가 현재 프로젝트에서 사용 중입니다. 그래도 삭제하시겠습니까?',
    });

    return true;
  };

  /**
   * 서버에서 이미지 사용 여부 확인
   * @returns 이미지 사용 여부
   */
  const checkServerUsage = async () => {
    const { data: imageUsageInfo } = await refetchServerUsage();
    if (!imageUsageInfo) return true;

    if (imageUsageInfo.usage === true || imageUsageInfo.count > 0) {
      const firstProjectName = imageUsageInfo.project_names[0].name;
      const message =
        imageUsageInfo.count > 1
          ? `삭제하려는 이미지가 '${firstProjectName}' 외 ${imageUsageInfo.count - 1}개의 프로젝트에서 사용 중입니다. 그래도 삭제하시겠습니까?`
          : `삭제하려는 이미지가 프로젝트 '${firstProjectName}'에서 사용 중입니다. 그래도 삭제하시겠습니까?`;
      // 모달 워닝 메시지 저장
      setDeleteWarningState({
        stage: 'server',
        message,
      });

      return true;
    }

    return false;
  };

  return {
    addImageLayer,
    changeImageSrc,
    checkLocalUsage,
    checkServerUsage,
    deleteWarningState,
  };
};
