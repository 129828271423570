import { useMutation } from '@tanstack/react-query';
import { brandAdminAxios } from './@core/brand';
import { DppIssueSchemaType } from './@schema/dppIssue';

/**
 * [ADMIN] DPP 발행 API
 */
const issueDpp = async (data: any) => {
  const res = await brandAdminAxios.post('/issue-dpps', data);

  return res?.data;
};

export const useDppIssue = () => {
  return useMutation({
    mutationFn: (data: any) => issueDpp(data),
  });
};
