import { UNITS } from '@lib/constants/admin/careLabel/element';

/**
 * 단위 변환 유틸리티 함수들
 */
// export const convertUnits = {
//   mmToPx: (mm: number) => mm * UNITS.MM_TO_PX,
//   pxToMm: (px: number) => px / UNITS.MM_TO_PX,
// };

const DPI = 300 as const;

export const pxToMm = (px: number) => (px / DPI) * 25.4;
export const mmToPx = (mm: number) => (mm * DPI) / 25.4;

/**
 * em 단위 값을 퍼센트 문자열로 변환
 */
export const emToPercent = (value: string): string => {
  if (!value) return '';
  return String(parseFloat(value.replace('em', '')) * 100);
};

/**
 * 퍼센트 값을 em 단위 문자열로 변환
 */
export const percentToEm = (value: string): string => {
  if (!value) return '';
  return `${parseFloat(value) / 100}em`;
};
