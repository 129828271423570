import { useQuery } from '@tanstack/react-query';
import { CareLabelDetailData } from '@customTypes/admin/careLabel';
import { useParams } from 'react-router-dom';
import { brandAdminAxios } from './@core/brand';
/**
 * [ADMIN] 케어라벨 상세 조회 API
 */
const getCareLabelDetail = async (no: string): Promise<CareLabelDetailData> => {
  const res = await brandAdminAxios.get(`/care-label-project-detail?no=${no}`);

  return res?.data;
};

export const useCareLabelDetail = () => {
  const { no: no } = useParams();

  return useQuery({
    queryKey: ['careLabelDetail', no],
    queryFn: () =>
      no ? getCareLabelDetail(no) : Promise.reject('No ID provided'),
    enabled: !!no,
  });
};
