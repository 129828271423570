import { CSSProperties, useCallback, useMemo } from 'react';
import { emToPercent } from '@lib/utils';
import { FontStyles, Layer } from '@customTypes/admin';
import { useLayerController } from './useLayerController';

interface StyleController {
  getLayersStyleValue: (styleKey: keyof CSSProperties) => string;
  handleStyleChange: (
    styleKey: keyof CSSProperties,
    styleValue: string | number
  ) => void;
  currFontStyles: FontStyles;
}

export const useStyleController = (pageId: string): StyleController => {
  const {
    layers,
    selectedLayers,
    getLayerById,
    updateLayerStyle,
    addLayerStyle,
  } = useLayerController(pageId);

  /**
   * 선택된 레이어들의 스타일 속성값을 확인하는 함수
   * 속성값이 다른 경우 'Mixed'를 반환
   */
  const getLayersStyleValue = useCallback(
    (styleKey: keyof Required<Layer>['style']): string => {
      let value = '';

      selectedLayers.forEach((layerId) => {
        const layer = getLayerById(layerId);
        if (!layer?.style?.[styleKey]) return;

        const nextValue = String(layer.style[styleKey]);

        if (value === '') {
          value = nextValue;
          return;
        }

        if (value !== nextValue) {
          value = 'Mixed';
          return;
        }
      });

      return value;
    },
    [layers, selectedLayers, getLayerById]
  );

  const handleStyleChange = useCallback(
    (styleKey: keyof CSSProperties, styleValue: string | number) => {
      if (selectedLayers.length === 0) return;

      selectedLayers.forEach((layerId) => {
        updateLayerStyle(layerId, styleKey, styleValue);
      });
    },
    [selectedLayers, addLayerStyle]
  );

  const currFontStyles = useMemo((): FontStyles => {
    const fontSize = getLayersStyleValue('fontSize')?.replace('px', '') ?? '';
    const lineHeight =
      getLayersStyleValue('lineHeight') === 'Mixed'
        ? 'Mixed'
        : emToPercent(getLayersStyleValue('lineHeight'));
    const letterSpacing =
      getLayersStyleValue('letterSpacing') === 'Mixed'
        ? 'Mixed'
        : emToPercent(getLayersStyleValue('letterSpacing'));

    return {
      fontFamily: getLayersStyleValue('fontFamily'),
      fontWeight: getLayersStyleValue('fontWeight'),
      fontSize,
      lineHeight,
      letterSpacing,
      textAlign: getLayersStyleValue('textAlign'),
    };
  }, [layers, selectedLayers, getLayerById, getLayersStyleValue]);

  return {
    getLayersStyleValue,
    handleStyleChange,
    currFontStyles,
  };
};
