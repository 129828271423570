import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import {
  careLabelDppPageAtom,
  careLabelDppPageItemsAtom,
  careLabelDppQueryParamsAtom,
} from '@store/admin/careLabel/new/dpp';
import {
  CareLabelDppListData,
  CareLabelTextile,
  DppListData,
} from '@customTypes/admin';
import { brandAdminAxios } from './@core/brand';

/**
 * [ADMIN] 케어라벨 생성 API
 */
const createCareLabel = async (data: any) => {
  const res = await brandAdminAxios.post(`/care-label-project`, data);

  return res?.data;
};

export const useCareLabelCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['careLabelCreate'],
    mutationFn: (data: any) => createCareLabel(data),
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: ['careLabelList'] }),
        queryClient.invalidateQueries({ queryKey: ['careLabelDetail'] }),
      ]);
    },
  });
};

/**
 * [ADMIN] 케어라벨 원단 종류 조회 기능 API
 */
const getCareLabelTextileList = async (): Promise<CareLabelTextile[]> => {
  const res = await brandAdminAxios.get(`/care-label-textile`);

  return res?.data;
};

export const useCareLabelTextileList = () => {
  return useQuery({
    queryKey: ['careLabelTextileList'],
    queryFn: () => getCareLabelTextileList(),
  });
};

interface DppListParams {
  page: number;
  items: number;
}

/**
 * [ADMIN] DPP 상세 조회 API
 */
const getCareLabelDppList = async (
  params: DppListParams
): Promise<DppListData> => {
  const res = await brandAdminAxios.get(`/dpp-list`, { params });

  return res?.data;
};

export const useCareLabelDppList = () => {
  const page = useAtomValue(careLabelDppPageAtom);
  const items = useAtomValue(careLabelDppPageItemsAtom);
  const params = {
    page,
    items,
  };

  return useQuery({
    queryKey: ['careLabelDppList', page, items],
    queryFn: () => getCareLabelDppList(params),
  });
};
